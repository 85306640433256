import React, { useState } from "react";
import "./RegisterAsExpert.css";
import axios from 'axios';


let RegisterAsExpert=()=>{

    const [title, setTitle] = useState(''); 
    const [firstName, setFirstName] = useState('');

    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');

    const [gender, setGender] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
   
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');

    const [city, setCity] = useState('');
    const [state, setState] = useState('');

    const [country, setCountry] = useState('');
    const [pincode, setPincode] = useState('');

    const [countryCode, setCountryCode] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [countryCodeAlternate, setCountryCodeAlternate] = useState("");
    const [alternateNumber, setAlternateNumber] = useState("");

    const [email, setEmail] = useState('');
    const [alternateEmail, setAlternateEmail] = useState('');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');  
   
    const [levelOfEducation, setLevelOfEducation] = useState('');
    const [collegeName, setCollegeName] = useState('');

    const [specialization, setSpecialization] = useState('');
    const [areaOfInterest, setAreaOfInterest] = useState(''); 

    const [experienceYear, setExperienceYear] = useState('');
    const [internationalexperienceYear, setInternationalexperienceYear] = useState('');
    const [servedAs, setServedAs] = useState('');

    const [areaOfExpertise, setAreaOfExpertise] = useState('');
    const [aboutMyself, setAboutMyself] = useState('');
   
     
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [selectedHearAboutUs, setSelectedHearAboutUs] = useState([]);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleMiddleNameChange = (e) => {
        setMiddleName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleDateOfBirthChange = (e) => {
        setDateOfBirth(e.target.value);
    };

    const handleGenderChange = (e) => {
        setGender(e.target.value);
    };


    const handleAddress1Change = (e) => {
        setAddress1(e.target.value);
    };

    const handleAddress2Change = (e) => {
        setAddress2(e.target.value);
    };

    const handleCityChange = (e) => {
        setCity(e.target.value);
    };

    const handleStateChange = (e) => {
        setState(e.target.value);
    };

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
    };

    const handlePincodeChange = (e) => {
        setPincode(e.target.value);
    };

   const handleCountryCodeChange = (event) => {
    const selectedCountryCode = event.target.value;
    setCountryCode(selectedCountryCode);
  };

  const handleContactNumberChange = (event) => {
    const enteredContactNumber = event.target.value;
    setContactNumber(enteredContactNumber);
  };

  const handleCountryCodeAlternateChange = (event) => {
    const selectedCountryCodeAlternate = event.target.value;
    setCountryCodeAlternate(selectedCountryCodeAlternate);
  };

  const handleAlternateNumberChange = (event) => {
    const enteredAlternateNumber = event.target.value;
    setAlternateNumber(enteredAlternateNumber);
  };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleAlternateEmailChange = (e) => {
        setAlternateEmail(e.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        // Reset the confirmPassword error if needed
        setConfirmPasswordError('');
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        // Validate if the confirm password matches the password
        if (event.target.value !== password) {
            setConfirmPasswordError('Passwords do not match');
        } else {
            setConfirmPasswordError('');
        }
    };

    const [resume, setResume] = useState(null);
    const handleResumeChange = (e) => {
        setResume(e.target.files[0]);
    };

    // const handleProfileImageChange = (e) => {
    //     setProfileImage(e.target.files[0]);
    // };

    const handleEducationLevelChange = (e) => {
        setLevelOfEducation(e.target.value);
    };

    const handleCollegeNameChange = (e) => {
        setCollegeName(e.target.value);
    };

    const handleSpecializationChange = (e) => {
        setSpecialization(e.target.value);
    };

    const handleAreaOfInterestChange = (e) => {
        setAreaOfInterest(e.target.value);
    };
 
    const handleExperienceYearChange = (e) => {
        setExperienceYear(e.target.value);
    };

    const handleInternationalexperienceYearChange = (e) => {
        setInternationalexperienceYear(e.target.value);
    };

    const handleServedAsChange = (e) => {
        setServedAs(e.target.value);
    };

    const handleAreaOfExpertiseChange = (e) => {
        setAreaOfExpertise(e.target.value);
    };

    const handleAboutMyselfChange = (e) => {
        setAboutMyself(e.target.value);
    };

    

    const interestOptions = [
        { value: 'Seminar', label: 'Seminar' },
        { value: 'Demo', label: 'Demo' },
        { value: 'Teaching', label: 'Teaching'},
        { value: 'Consultation',label:'Consultation'},
        { value: 'Faculty' , label: 'Faculty'},
        { value: 'Coach/Guide', label:'Coach/Guide'},
        { value: 'Management', label: 'Management'},
        { value: 'Development', label: 'Development'},
        { value: 'Admin', label: 'Admin'},
        { value: 'Freelancer', label: 'Freelancer'}
    // ... other options
  ];
    
    
    const hearAboutUsOptions = [
        { value: 'News', label: 'News' },
        { value: 'Social Media', label: 'Social Media' },
        { value: 'Magazine', label:'Magazine'},
        { value: 'Advertisement', label: 'Advertisement' },
        { value: 'Google Search', label: 'Google Search' },
        { value: 'Friends', label: 'Friends'},
        { value: 'Others', label: 'Others' }
        // ...other options
    ];

  
    const handleInterestChange = (value) => {
        if (selectedInterests.includes(value)) {
          setSelectedInterests(selectedInterests.filter(item => item !== value));
        } else {
          setSelectedInterests([...selectedInterests, value]);
        }
      };

    const handleHearAboutUsChange = (value) => {
       
        if (selectedHearAboutUs.includes(value)) {
            setSelectedHearAboutUs(selectedHearAboutUs.filter(item => item !== value));
        } else {
            setSelectedHearAboutUs([...selectedHearAboutUs, value]);
        }
    };

    const [profileImageFile, setProfileImageFile] = useState(null);
    // const [uploadStatus, setUploadStatus] = useState(null);
    const handleProfileImageChange = (event) => {
        const file = (event.target.files[0]);
        const reader = new FileReader();

        reader.onload=()=>{
            setProfileImageFile(reader.result)
        }
        reader.readAsDataURL(file);
    };
    
   console.log(profileImageFile);

    const [agreeChecked, setAgreeChecked] = useState(false);

    const handleAgreeChange = (e) => {
        setAgreeChecked(e.target.checked);
    };

    const handleSubmit =async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match');
            return;
        }


        if (selectedInterests.length === 0 ) {
            alert('Please make sure to select at least one interest ');
            return;
        }

        if ( selectedHearAboutUs.length === 0) {
            alert('Please make sure to select at least one option for hearing about us.');
            return;
        }
        
        try {
            const formData = {
                title,
                firstName,
                middleName,
                lastName,
                gender,
                dateOfBirth,
                address1,
                address2,
                city,
                state,
                country,
                pincode,
                countryCode,
                contactNumber,
                countryCodeAlternate,
                alternateNumber,
                email,
                alternateEmail,
                password,
                confirmPassword,
                levelOfEducation,
                collegeName,
                specialization,
                areaOfInterest,
                experienceYear,
                internationalexperienceYear,
                servedAs,
                areaOfExpertise,
                aboutMyself,
                selectedInterests,
                selectedHearAboutUs,

                profileImageFile,
                resume
                
            };

        await axios.post('http://localhost:5400/api/experts/submitForm', formData);
            console.log('Expert registered successfully');
            alert('Expert registration was successfull');
            
        } catch (error) {
            console.error('Expert registration was unsuccessfull ', error);
            alert('Expert registration was unsuccessfull');
        }
};

    return(

        <div className="container pmg-fontfamily">
        
          <div className="mt-2">
            <h3 style={{ color: "brown" }}>Expert Registration</h3>
            <p>Welcome, You are just few  steps away from creating your account!</p>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-10 col-md-10">
                        <h4 style={{ color: "brown" }}>                  
                            Tell us about yourself
                        </h4>
                        </div>    
                    </div>
                 
                   <form onSubmit={handleSubmit}>
                        <div>
                            <div className="row">
                                {/* Title Dropdown */}
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <select
                                            className="form-select"
                                            id="title"
                                            value={title}
                                            onChange={handleTitleChange}
                                            //required
                                        >
                                            <option value="">Title</option>
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss">Miss</option>
                                            <option value="Dr">Dr</option>
                                        </select>
                                    </div>
                                </div>
        
                                {/* First Name Input */}
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="firstName"
                                            value={firstName}
                                            placeholder='First Name*'
                                            onChange={handleFirstNameChange}
                                            //required
                                        />
                                    </div>
                                </div>
                            </div>
                                 
                            <div className="row">     {/* Middle Name Input */}
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="middleName"
                                            value={middleName}
                                            placeholder="Middle Name"
                                            onChange={handleMiddleNameChange}
                                        />
                                    </div>
                                </div>

                                {/* Last Name Input */}
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lastName"
                                            value={lastName}
                                            placeholder="Last Name*"
                                            onChange={handleLastNameChange}
                                            //required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <h6>Date of Birth</h6>
                                    <div className="input-group input-group-sm">
                                        
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="dateOfBirth"
                                            value={dateOfBirth}
                                            onChange={handleDateOfBirthChange}
                                            //required
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <h6>Gender</h6>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="gender"
                                            id="male"
                                            value="Male"
                                            checked={gender === "Male"}
                                            onChange={handleGenderChange}
                                            //required
                                        />Male
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="gender"
                                            id="female"
                                            value="Female"
                                            checked={gender === "Female"}
                                            onChange={handleGenderChange}
                                            //required
                                        />Female
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="gender"
                                            id="others"
                                            value="Others"
                                            checked={gender === "Others"}
                                            onChange={handleGenderChange}
                                            //required
                                        />Others
                                    </div>
                                </div>
                            </div>

        
                            <div className="row mt-2">
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="address1"
                                            value={address1}
                                            placeholder="Address 1*"
                                            onChange={handleAddress1Change}
                                            //required
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="address2"
                                            value={address2}
                                            placeholder="Address 2"
                                            onChange={handleAddress2Change}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="city"
                                            value={city}
                                            onChange={handleCityChange}
                                            placeholder="City*"
                                            //required
                                            
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <select
                                            className="form-select"
                                            id="state"
                                            value={state}
                                            onChange={handleStateChange}
                                            //required
                                        >
                                            <option value="">State*</option>
                                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                                            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                            <option value="Assam">Assam</option>
                                            <option value="Bihar">Bihar</option>
                                            <option value="Chhattisgarh">Chhattisgarh</option>
                                            <option value="Goa">Goa</option>
                                            <option value="Gujarat">Gujarat</option>
                                            <option value="Haryana">Haryana</option>
                                            <option value="Himachal Pradesh">Himachal Pradesh</option>
                                            <option value="Jharkhand">Jharkhand</option>
                                            <option value="Karnataka">Karnataka</option>
                                            <option value="Kerala">Kerala</option>
                                            <option value="Madhya Pradesh">Madhya Pradesh</option>
                                            <option value="Maharashtra">Maharashtra</option>
                                            <option value="Mizoram">Mizoram</option>
                                            <option value="Nagaland">Nagaland</option>
                                            <option value="Odisha">Odisha</option>
                                            <option value="Punjab">Punjab</option>
                                            <option value="Rajasthan">Rajasthan</option>
                                            <option value="Sikkim">Sikkim</option>
                                            <option value="Tamil Nadu">Tamil Nadu</option>
                                            <option value="Telangana">Telangana</option>
                                            <option value="Tripura">Tripura</option>
                                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                                            <option value="Uttarakhand">Uttarakhand</option>
                                            <option value="West Bengal">West Bengal</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <select
                                            className="form-select"
                                            id="country"
                                            value={country}
                                            onChange={handleCountryChange}
                                            //required                                            
                                       >
                                            <option value="">Country*</option>
                                            <option value="India">India</option>
                                            <option value="USA">USA</option>
                                            <option value="Dubai">Dubai</option>
                                            {/* Other country options */}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="pincode"
                                            value={pincode}
                                            onChange={handlePincodeChange}
                                            placeholder="Pincode*"
                                            //required
                                        />
                                    </div>
                                </div>
                            </div>
        
                            <div className="row">
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                    <select
                                        className="form-select me-1" // Adjust the classes here
                                        id="countryCode"
                                        value={countryCode}
                                        onChange={handleCountryCodeChange}
                                        style={{ maxWidth: "100px" }} 
                                        //required
                                    >
                                        <option value="+">+</option>
                                        <option value="+91">+91</option>
                                        <option value="+1">+1</option>
                                        {/* Add more country codes as needed */}
                                    </select>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="contactNumber"
                                        value={contactNumber}
                                        onChange={handleContactNumberChange}
                                        placeholder="Contact Number*"
                                        //required
                                    />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                    <select
                                        className="form-select me-1" // Adjust the classes here
                                        id="countryCodeAlternate"
                                        value={countryCodeAlternate}
                                        onChange={handleCountryCodeAlternateChange}
                                        style={{ maxWidth: "100px" }} 
                                        //required
                                    >
                                        <option value="+">+</option>
                                        <option value="+91">+91</option>
                                        <option value="+1">+1</option>
                                        {/* Add more country codes as needed */}
                                    </select>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="alternateNumber"
                                        value={alternateNumber}
                                        onChange={handleAlternateNumberChange}
                                        placeholder="What's App Number*"
                                        //required
                                    />
                                    </div>
                                </div>
                            </div>
        
                            <div className="row">
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            placeholder="E-mail*"
                                           
                                            //required
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="alternateEmail"
                                            value={alternateEmail}
                                            onChange={handleAlternateEmailChange}
                                            placeholder="Alternate E-mail"
                                        
                                        />
                                    </div>
                                </div>
                            </div>
        
                            <div className="row">
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Pin*"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            
                                            //required
                                        />
                                    </div>
                                    <p className="pmg-note-points-color pmg-small-text emphasized">*Set 6 digit Pin</p>
                                </div>
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Confirm Pin*"
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                 
                                            //required
                                        />
                                    </div>
                                    <p className="pmg-note-points-color pmg-small-text emphasized">*Set 6 digit Confirm Pin</p>
                                    {confirmPasswordError && (
                                        <div className="text-danger">{confirmPasswordError}</div>
                                    )}
                                </div>
                            </div>
        
                            {/* <div className="row"> */}
                                {/* <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="resume"
                                            name="resume"
                                            accept=".doc, .docx, .pdf"
                                            onChange={handleResumeChange}
                                        />
                                    </div>
                                    <p className="pmg-note-points-color pmg-small-text emphasized">
                                        Resume Format: doc,
                                        
                                        docx, pdf and Resume size: Up to 8MB
                                    </p>
                                </div> */}
                                {/* <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="profileImage"
                                            name="profileImage"
                                            accept=".jpg, .jpeg, .png"
                                            onChange={handleProfileImageChange}
                                        />
                                    </div>
                                    <p className="pmg-note-points-color pmg-small-text emphasized">
                                        Photo Format: jpg, jpeg and Size cannot be more than 100KB
                                    </p>
                                </div> */}

                            {/* </div> */}
        

                            <div className='row'>
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="resume"
                                            name="resume"
                                            accept=".doc, .docx, .pdf"
                                            value={resume}
                                            onChange={handleResumeChange}
                                        />
                                    </div>
                                    <p className="pmg-note-points-color pmg-small-text emphasized">
                                        Resume Format: doc,
                                        
                                        docx, pdf and Resume size: Up to 8MB
                                    </p>
                                </div>
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <h6>Profile Image</h6>
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="profileImage"
                                            name="profileImage"
                                            accept=".jpg, .jpeg, .png"
                                            onChange={handleProfileImageChange}
                                        />
                                    </div>
                                    <p className="pmg-note-points-color pmg-small-text emphasized">
                                        Photo Format: jpg, jpeg and Size cannot be more than 100KB
                                    </p>
                                </div>
                            </div>

                            <div className="row ">
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <select
                                            className="form-select form-select-sm"
                                            placeholder="Education*"
                                            id="sel1"
                                            name="levels"
                                            value={levelOfEducation}
                                            onChange={handleEducationLevelChange}
                                            //required
                                        >
                                            <option value="">Your Qualification*</option>
                                            <option value="Doctorate/PhD">doctorate/PhD</option>
                                            <option value="Masters/Post-graduation">masters/post-graduation</option>
                                            <option value="Degree/Graduation">Degree/Graduation</option>
                                            <option value="Higher Secondary School">Higher Secondary School</option>
                                            <option value="Secondary School">Secondary School</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            rows="2"
                                            className="form-control"
                                            name="countries"
                                            placeholder="College Name/Institute Name/University Name*"
                                            value={collegeName}
                                            onChange={handleCollegeNameChange}
                                            //required
                                        />
                                    </div>
                                </div>
                            </div>
            
                            {/* Specialization and Area of Interests fields */}
                            <div className="row">
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={specialization}
                                            onChange={handleSpecializationChange}
                                            placeholder="Your Specialization*"
                                            //required
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={areaOfInterest}
                                            onChange={handleAreaOfInterestChange}
                                            placeholder="Area of Interests*"
                                            //required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6 col-md-3 mt-2">
                                    <div className="input-group input-group-sm">
                                        <select
                                            className="form-select"
                                            id="experienceYear"
                                            name="experienceYear"
                                            onChange={handleExperienceYearChange}
                                            value={experienceYear}
                                            //required
                                        >
                                        <option value="">Years of Experience*</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-3 col-md-3 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="countries"
                                            name="countries"
                                            placeholder="Your International Experience*"
                                            onChange={handleInternationalexperienceYearChange}
                                            value={internationalexperienceYear}
                                            //required
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-3 col-md-6  mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="servedAs"
                                            name="servedAs"
                                            placeholder="Your Current/Previous Service Details*"
                                            onChange={handleServedAsChange}
                                            value={servedAs}
                                            //required
                                        />
                                    </div>
                                </div>
                            </div>
                            
                        
                            <div className="row">
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="areaOfExpertise"
                                            name="areaOfExpertise"
                                            placeholder="Area of Expertise*"
                                            onChange={handleAreaOfExpertiseChange}
                                            value={areaOfExpertise}
                                            //required
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 mt-2">
                                    <div className="input-group input-group-sm">
                                        <textarea  
                                            className="form-control"
                                            id="aboutMyself"
                                            name="aboutMyself"
                                            placeholder="Briefly write about yourself*"
                                            onChange={handleAboutMyselfChange}
                                            value={aboutMyself}
                                            //required
                                        />
                                    </div>
                                </div>
                            </div>
    
                            <div className="row">
                                <div className="col-md-6">
                                    <p htmlFor="interest">
                                        <p>Interest In*</p>
                                    </p><br />
                                    {interestOptions.map(option => (
                                        <div className="form-check form-check-inline" key={option.value}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="selectedInterest"
                                                id={`interestRadio_${option.value}`}
                                                value={option.value}
                                                onChange={() => handleInterestChange(option.value)}
                                                checked={selectedInterests.includes(option.value)}
                                            />
                                            <p className="form-check-label" htmlFor={`interestRadio_${option.value}`}>
                                                {option.label}
                                            </p>
                                        </div>
                                    ))}
                                </div>

                                <div className="col-md-6">
                                    <p htmlFor="hearAboutUs">
                                        <p>How did you hear about us*</p>
                                    </p><br />
                                    {hearAboutUsOptions.map(option => (
                                        <div className="form-check form-check-inline" key={option.value}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="selectedHearAboutUs"
                                                id={`hearAboutUsRadio_${option.value}`}
                                                value={option.value}
                                                onChange={() => handleHearAboutUsChange(option.value)}
                                                checked={selectedHearAboutUs.includes(option.value)}
                                            />
                                            <p className="form-check-label" htmlFor={`hearAboutUsRadio_${option.value}`} >
                                                {option.label}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
            
                            <div className="row ">
                                <div className="col-sm-12 col-md-12 mt-3">
                                    <div className="form-check">
                                      
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="check1"
                                            name="option1"
                                            value="something"
                                            checked={agreeChecked}
                                            onChange={handleAgreeChange}
                                        />
                                        <p className="form-check-label" htmlFor="check1">
                                            I agree this platform utilizes my personal information for any communication. I accept  
                                            {" "} 
                                            <a href="#" className="pmg-terms-link">
                                            terms and conditions
                                            </a>{" "}
                                            of the platform.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="text-center">
                                    {/* Disable the "Register" button based on the checkbox state */}
                                    <button
                                        type="submit"
                                        className="btn btn-danger btn-sm px-3 me-2"
                                        disabled={!agreeChecked}
                                        value="submit"
                                    >
                                        Register
                                    </button>
                                </div>
                            </div>

                        </div>
                   </form>
    
                </div>
            </div>
           
          </div>
        </div>
      )
}

export default RegisterAsExpert