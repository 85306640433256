import React, { useEffect, useState } from 'react';
import './ProfileCardDetails.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
const ProfileCardsDetailsList2= () => {

  // const [OrganizationData, setProfileData] = useState([]);
 

  // useEffect(() => {
  //   // Fetch organization profile data from the backend API
  //   axios.get('http://localhost:5400/api/organizationProfiles') // Update the endpoint URL
  //     .then(response => {
  //       setProfileData(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching organization profile data:', error);
  //     });
  // }, []);








  const { id } = useParams();
  const [data, setdata] = useState(null);

  useEffect(() => {
    // Make an API call to fetch card details based on the id
    axios.get(`http://localhost:5400/api/organizationProfiles/${id}`) // Adjust the endpoint as per your API
      .then((response) => {
        setdata(response.data);
      })
      .catch((error) => {
        console.error('Error fetching card data:', error);
      });
  }, [id]);

  // Render card data here
  if (!data) {
    return <div>Loading...</div>;
  }

  return (
       
       <div className="container pmg-fontfamily">
          <section  style={{ backgroundColor: '#eee' }}>
            <div className="container py-3 me-0 ">
              <div className="row">
                <div className="col">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item active" aria-current="page">
                        User Profile
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
      
              {/* {OrganizationData.map((data, index) => ( */}
                <div className="row" key={data.id}>
                  <div className="col-lg-4">
                    <div className="card mb-4">
                      <div className="card-body text-center">
                        <h3 className="my-3">{data.orgname}</h3>
                        {/* ... rest of your card content */}
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                          alt="avatar"
                          className="rounded-circle img-fluid"
                          style={{ width: '150px' }}
                        />
                        <p className="text-muted mb-2 mt-3">{`${data.city} , ${data.state}`}</p>
                        <div className="d-flex justify-content-center mb-2">
                        <Link to="/EmailPage">
                        <button type="button" className="btn btn-danger btn-sm px-3 me-2">Invite</button>
                        </Link>
                        <Link to="/livechat">
                        <button type="button" className="btn btn-danger btn-sm px-3 me-2">Chat</button>
                        </Link>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                                  <div className="card mb-4">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <p className="mb-0">Contact Person</p>
                                        </div>
                                        <div className="col-sm-6">
                                          <p className="text-muted mb-0">{data.pocname}</p>
                                        </div>
                                      </div>
                                      <hr style={{ margin: '0.7rem 0' }}/>
                                
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <p className="mb-0">Contact Number</p>
                                        </div>
                                        <div className="col-sm-6">
                                          <p className="text-muted mb-0">{`${data.countrycode} ${data.contactnumber}`}</p>
                                        </div>
                                      </div>
                                      <hr style={{ margin: '0.7rem 0' }}/>
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <p className="mb-0">Email</p>
                                        </div>
                                        <div className="col-sm-6">
                                          <p className="text-muted mb-0">{data.email}</p>
                                        </div>
                                      </div>
                                      <hr style={{ margin: '0.7rem 0' }}/>
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <p className="mb-0">Gender</p>
                                        </div>
                                        <div className="col-sm-6">
                                          <p className="text-muted mb-0">Male</p>
                                        </div>
                                      </div>
                                      <hr style={{ margin: '0.7rem 0' }}/>
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <p className="mb-0">Size of Organization</p>
                                        </div>
                                        <div className="col-sm-6">
                                          <p className="text-muted mb-0">{data.orgsize} Members</p>
                                        </div>
                                      </div>
                                      <hr style={{ margin: '0.7rem 0' }}/>
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <p className="mb-0">Designation</p>
                                        </div>
                                        <div className="col-sm-6">
                                          <p className="text-muted mb-0">{data.pocdesignation}</p>
                                        </div>
                                      </div>
                                      <hr style={{ margin: '0.7rem 0' }}/>
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <p className="mb-0">Working Hours</p>
                                        </div>
                                        <div className="col-sm-6">
                                          <p className="text-muted mb-0">Mon-sat (9AM-6PM)</p>
                                        </div>
                                      </div>
                                      <hr style={{ margin: '0.7rem 0' }}/>
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <p className="mb-0">Website Link</p>
                                        </div>
                                        <div className="col-sm-8">
                                          <p className="text-muted mb-0">{data.orgwebsite}</p>
                                        </div>
                                      </div>
                                      <hr style={{ margin: '0.7rem 0' }}/>
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <p className="mb-0">Area of Interest</p>
                                        </div>
                                        <div className="col-sm-6">
                                          <p className="text-muted mb-0">{data.selectedinterests}</p>
                                        </div>
                                      </div>
                                      <hr style={{ margin: '0.7rem 0' }}/>
                                      <div className="row">
                                        <div className="col-sm-4">
                                          <p className="mb-0">Focused Market</p>
                                        </div>
                                        <div className="col-sm-6">
                                          <p className="text-muted mb-0">{data.orgfocused}</p>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                </div>
              {/* ))} */}
            
            </div>
          </section>
      </div>
      );
}

export default ProfileCardsDetailsList2;
