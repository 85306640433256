import React, { useState } from 'react';
import './TwilioWhatsApp.css';

function TwilioWhatsApp() {
  const [toNumber, setToNumber] = useState('');
  const [messageContent, setMessageContent] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('http://localhost:5400/send-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ toNumber, messageContent }),
      });

      if (response.ok) {
        alert('Message sent successfully');
      } else {
        alert('An error occurred while sending the message');
      }
    } catch (error) {
      console.error(error);
      alert('An error occurred while sending the message');
    }
  };

  return (
    <div className="twilio-container pmg-fontfamily">
      <h1>Send Twilio Message</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="toNumber">To Number:</label>
        <input
          type="text"
          id="toNumber"
          value={toNumber}
          onChange={(e) => setToNumber(e.target.value)}
          required
        />
        <br />
        <label htmlFor="messageContent">Message Content:</label>
        <textarea
          type="text"
          id="messageContent"
          value={messageContent}
          
          onChange={(e) => setMessageContent(e.target.value)}
          required
        />
        <br />
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
}

export default TwilioWhatsApp;
