import React from 'react';
import './App.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './Component2/Navbar';

import HomePage from './Component2/HomePage';
import MessagePage from './Component2/MessagePage';
import ServicesPage from './Component2/ServicesPage';
import CreateEvent from './Component2/CreateEvent.jsx';
import EventList from './Component2/EventList';

import EventListDetails from './Component2/EventListDetails';

import ContactUsPage from './Component2/ContactUsPage';
import RegistrationPage from './Component2/RegistrationPage';
import RegisterAsExpert from './Component2/RegisterAsExpert';
import RegisterAsOrganization from './Component2/RegisterAsOrganization';
import LoginPage from './Component2/LoginPage';
import NavbarFooter from './Component2/NavbarFooter';
import UserNavbar from './Component2/UserNavbar';
import UserNavbar2 from './Component2/UserNavbar2';
import EmailPage from './Component2/EmailPage';
import ProfileCardsDetailsList from './Component2/ProfileCardsDetailsList';
import ProfileCardsDetailsList2 from './Component2/ProfileCardsDetailsList2';

import Forgotpassword from './Component2/Forgotpassword';
import TwilioSms from './Component2/TwilioSms';



import WhatsApp from './Component2/WhatsApp';


import TwilioWhatsApp from './Component3/TwilioWhatsApp';
import EventRegistration from './Component2/EventRegistration';
import Team from './Component2/Team';
import Feedback from './Component2/FeedbackPage';

// import Login from './Component3/Login';







function App() {
  return (
   
    <React.Fragment>

        
    {/* ----------- */}
   {/* main code routes all  start*/}

    <BrowserRouter>
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <Navbar></Navbar>
                <div style={{ flex: "1", paddingBottom: "60px" }}>
                    <Routes>
                        <Route path="/" element={<HomePage></HomePage>}></Route>

                        <Route path="/events" element={<EventList />} />
                        <Route path="/events/:eventid" element={<EventListDetails  />} />
                        <Route path="/event-create" element={<CreateEvent />} />
                        <Route path="/event-registration/:eventid" element={<EventRegistration />}></Route>
                        <Route path='/services' element={<ServicesPage></ServicesPage>}></Route>
                        <Route path='/message' element={<MessagePage></MessagePage>}></Route>
                        <Route path='/livechat' element={<ContactUsPage></ContactUsPage>}></Route>
                        <Route path='/register' element={<RegistrationPage></RegistrationPage>}></Route>
                        <Route path='/registerasexpert' element={<RegisterAsExpert></RegisterAsExpert>}></Route>
                        <Route path='/registerasorganization' element={<RegisterAsOrganization></RegisterAsOrganization>}></Route>
                        <Route path='/login' element={<LoginPage></LoginPage>}></Route>
                        <Route path='/loginasexpert' element={<LoginPage />}/>
                        <Route path='/loginasorganization' element={<LoginPage />}/>
                        <Route path='/UserNavbar' element={<UserNavbar></UserNavbar>}></Route>
                        <Route path='/UserNavbar2' element={<UserNavbar2></UserNavbar2>}></Route>
                        <Route path='/EmailPage' element={<EmailPage></EmailPage>}></Route>
                        <Route path='/ProfileCardsDetailsList/:id' element={<ProfileCardsDetailsList></ProfileCardsDetailsList>}></Route>
                        <Route path='/ProfileCardsDetailsList2/:id' element={<ProfileCardsDetailsList2></ProfileCardsDetailsList2>}></Route>
                        <Route path="/forgotpassword" element={<Forgotpassword></Forgotpassword>}></Route>
                        <Route path='/twiliosms' element={<TwilioSms></TwilioSms>}></Route>
                        <Route path='/whatsapp' element={<WhatsApp></WhatsApp>}></Route>
                        <Route path="/team" element={<Team></Team>}></Route>
                        <Route path="/feedback" element={<Feedback></Feedback>}></Route>
                        
                    </Routes>
                </div>
            <NavbarFooter></NavbarFooter>
        </div>
    </BrowserRouter>

    {/* main code routes all  end*/}

    {/* <UserNavbar></UserNavbar>
       <UserNavbar2></UserNavbar2> */}
     
     {/* <WhatsApp></WhatsApp> */}
       {/* <ForgotPass></ForgotPass> */}
       {/* <Login></Login> */}
  {/* <Feedback></Feedback>        */}

    </React.Fragment>
   
    
   
  );
}

export default App;



