import React, { useState } from 'react';
import './WhatsApp.css'; // Import your CSS file for styling


function WhatsApp() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [whatsappLink, setWhatsappLink] = useState('');
  const [error, setError] = useState(null);

  const sendWhatsAppMessage = () => {
    fetch('http://localhost:5400/send-whatsapp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phone_number: phoneNumber, message: message }),
    })
      .then((response) => response.json())
      .then((data) => {
        setWhatsappLink(data.whatsapp_link);
        setError(null);
       
      })
      .catch((error) => {
        setError('Error sending WhatsApp message.');
        console.error(error);
      });
  };

  return (
    <div className='mt-4'>
    <div className="app-container pmg-fontfamily">
      <h1 className="app-title">Send WhatsApp Message</h1>

      <div className="form-group">
        <label htmlFor="phone_number">Phone Number:</label>
        <input
          type="text"
          id="phone_number"
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          placeholder="Enter your message"
          value={message}
          rows="4"
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>
      </div>

      <div className="form-group text-center">
        {/* <a href={whatsappLink} target="_blank" rel="noopener noreferrer"> */}
        <button className="send-button" onClick={sendWhatsAppMessage}>
          Send 
        </button> 
        {/* </a> */}
      </div>

      {error && <p className="error">{error}</p>}

      {whatsappLink && (
        <div className="whatsapp-link">
          <p>WhatsApp Link:</p>
          <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
            {whatsappLink}
          </a>
        </div>
      )}
    </div>
    </div>
  );
}

export default WhatsApp;

