import React, { useEffect, useState } from 'react';
import './ProfileCardDetails.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
const ProfileCardsDetailsList = () => {

  const { id } = useParams();
  const [data, setdata] = useState(null);

  useEffect(() => {
    // Make an API call to fetch card details based on the id
    axios.get(`http://localhost:5400/api/expertProfiles/${id}`) // Adjust the endpoint as per your API
      .then((response) => {
        setdata(response.data);
      })
      .catch((error) => {
        console.error('Error fetching card data:', error);
      });
  }, [id]);

  // Render card data here
  if (!data) {
    return <div>Loading...</div>;
  }
  





    return (
       
        // style={{ backgroundColor: '#eee' }}
            <section  style={{ backgroundColor: '#eee' }}>
              <div className="container pmg-fontfamily py-3 me-0">
                <div className="row">
                  <div className="col">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">
                          User Profile
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              
                <div className="row" key={data.id}>
                  <div className="col-lg-4">
                    <div className="card mb-4">
                      <div className="card-body text-center">
                        <h3 className="my-3">{`${data.title} ${data.firstname} ${data.lastname}`}</h3>
                        <img
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                          alt="avatar"
                          className="rounded-circle img-fluid"
                          style={{ width: '150px' }}
                        />
                        <p className="text-muted mb-2 mt-3">{data.specialization}</p>
                        <div className="d-flex justify-content-center mb-2">
                          <p className="text-muted">{`${data.city} , ${data.state}`}</p>
                        </div>
                      <div className="d-flex justify-content-center mb-2">
                        <Link to="/EmailPage">
                        <button type="button" className="btn btn-danger btn-sm px-3 me-2">Invite</button>
                        </Link>
                        <Link to="/livechat">
                        <button type="button" className="btn btn-danger btn-sm px-3 me-2">Chat</button>
                        </Link>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-4">
                            <p className="mb-0">Contact Number</p>
                          </div>
                          <div className="col-sm-6">
                            <p className="text-muted mb-0">{`${data.countrycode} ${data.contactnumber}`}</p>
                          </div>
                        </div>
                        <hr style={{ margin: '0.9rem 0' }}/>
                        <div className="row">
                          <div className="col-sm-4">
                            <p className="mb-0">Email</p>
                          </div>
                          <div className="col-sm-6">
                            <p className="text-muted mb-0">{data.email}</p>
                          </div>
                        </div>
                        <hr  style={{ margin:'0.9rem 0' }}/>
                        <div className="row">
                          <div className="col-sm-4">
                            <p className="mb-0">Gender</p>
                          </div>
                          <div className="col-sm-6">
                            <p className="text-muted mb-0">{data.gender}</p>
                          </div>
                        </div>
                        <hr  style={{ margin: '0.9rem 0' }} />
                        <div className="row">
                          <div className="col-sm-4">
                            <p className="mb-0">Education</p>
                          </div>
                          <div className="col-sm-6">
                            <p className="text-muted mb-0">{data.levelofeducation}</p>
                          </div>
                        </div>
                        <hr  style={{ margin: '0.9rem 0' }}/>
                        <div className="row">
                          <div className="col-sm-4">
                            <p className="mb-0">College</p>
                          </div>
                          <div className="col-sm-6">
                            <p className="text-muted mb-0">{data.collegename}</p>
                          </div>
                        </div>
                        <hr  style={{ margin: '0.9rem 0' }}/>
                        <div className="row">
                          <div className="col-sm-4">
                            <p className="mb-0">Experience</p>
                          </div>
                          <div className="col-sm-6">
                            <p className="text-muted mb-0">{data.experienceyear}</p>
                          </div>
                        </div>
                        <hr  style={{ margin:'0.9rem 0' }}/>
                        <div className="row">
                          <div className="col-sm-4">
                            <p className="mb-0">Area of Interest</p>
                          </div>
                          <div className="col-sm-6">
                            <p className="text-muted mb-0">{data.areaofinterest}</p>
                          </div>
                        </div>
                        <hr  style={{ margin:'0.9rem 0' }}/>
                        <div className="row">
                          <div className="col-sm-4">
                            <p className="mb-0">Area of Expertise</p>
                          </div>
                          <div className="col-sm-6">
                            <p className="text-muted mb-0">{data.areaofexpertise}</p>
                          </div>
                        </div>

                        <hr  style={{ margin:'0.9rem 0' }}/>
                        <div className="row">
                          <div className="col-sm-4">
                            <p className="mb-0">Focused Market</p>
                          </div>
                          <div className="col-sm-6">
                            <p className="text-muted mb-0">Focused Market</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>           
              </div>
            </section>
      );
}

export default ProfileCardsDetailsList;
