import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CreateEvent.css';

const CreateEvent = () => {
  const [phone, setPhone] = useState('');
  const [eventdate, setEventDate] = useState('');
  const [eventtime, setEventTime] = useState('');
  const [eventdetails, setEventDetails] = useState('');
  const [eventimage, setEventImage] = useState('');
  const [description, setDescription] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [phoneVerified, setPhoneVerified] = useState(false);



  const handleChangePhoto = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setEventImage(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Handle phone number validation
  const validatePhone = async () => {
    try {
      const response = await axios.post("http://localhost:5400/phone", { phone });
      if (response.data.verified) {
        setPhoneVerified(true);
      } else {
        setPhoneVerified(false);
      }
    } catch (error) {
      console.error('Error validating phone number:', error);
    }
  };

  useEffect(() => {
    if (phone) {
      validatePhone();
    }
  }, [phone]);

  // Handle event creation submission
  const handleCreate = async (e) => {
    e.preventDefault();
    
    if (!phoneVerified) {
      alert("Phone number not verified. Please verify the phone number.");
      return;
    }

    // Create an object containing the event creation data
    const eventCreationData = {
      phone,
      eventdate,
      eventtime,
      eventdetails,
      eventimage,
      description,
    };

    try {
      // Send a POST request to your server
      const response = await axios.post("http://localhost:8098/create-event", eventCreationData);

      if (response.status === 200) {
        // Event creation was successful, show a success message and clear input values
        setSuccessMessage('Event created successfully');
        setEventDate('');
        setEventTime('');
        setEventDetails('');
        setEventImage('');
        setDescription('');
      }
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };
console.log(phoneVerified)
  return (
    <div className="container-3 m-3">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h1>Create Event</h1>
          <form>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                
              />
               {phoneVerified ? (
                  <p className="text-success">Phone verified</p>
                ) : (
                  <p className="text-danger">Not verified</p>
                )}
            </div>
            <div className="mb-3">
              <label htmlFor="eventdate" className="form-label">
                  Event Date
              </label>
              <input
                  type="date"
                  className="form-control"
                  id="eventdate"
                  value={eventdate}
                  onChange={(e) => setEventDate(e.target.value)}
                  required
              />
              </div>
              <div className="mb-3">
              <label htmlFor="eventtime" className="form-label">
                  Event Time
              </label>
              <input
                  type="time"
                  className="form-control"
                  id="eventtime"
                  value={eventtime}
                  onChange={(e) => setEventTime(e.target.value)}
                  required
              />
              </div>
              <div className="mb-3">
              <label htmlFor="eventdetails" className="form-label">
                  Event Details
              </label>
              <input
                  type="text"
                  className="form-control"
                  id="eventdetails"
                  value={eventdetails}
                  onChange={(e) => setEventDetails(e.target.value)}
                  required
              />
              </div>
              <div className="mb-3">
              <label htmlFor="eventimage" className="form-label">
                  Event Image (URL or File Input)
              </label>
              <input
                  type="file" // Assuming eventimage is a URL, change to "file" if it's a file input
                  className="form-control"
                  id="eventimage"
                  onChange={handleChangePhoto}
                  required
              />
              </div>
              <div className="mb-3">
              <label htmlFor="description" className="form-label">
                  Description
              </label>
              <input
                  type="text"
                  className="form-control"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
              />
              </div>
              <div className="centered-container">
                <button
                  type="button"
                  className="btn btn-sm btn-danger inline-button"
                  onClick={handleCreate}
                  disabled={!phoneVerified}
                >
                  Create
                </button>
              </div>
            {successMessage && (
              <div className="alert alert-success mt-3">
                {successMessage}
              </div>
            )}
            {!phoneVerified && (
              <div className="alert alert-danger mt-3">
                Phone number not verified.
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;