import React from "react";
import "./EmailPage.css";
import axios from 'axios';
// const InviteForm = () => {


//     return (
        
//         <section id="contact">
  
 
  
//   <div className="contact-wrapper">
//         <form id="contact-form" className="form-horizontal" role="form" >
       
//         {/* <div className="form-group">
//           <div className="col-sm-12">
//             <input type="text" className="form-controls" id="name" placeholder="NAME" name="name" value="" required />
//           </div>
//         </div>
//    */}
//         {/* <div className="form-group">
//           <div className="col-sm-12">
//             <input type="email" className="form-controls" id="email" placeholder="EMAIL" name="email" value="" required />
//           </div>
//         </div> */}
//         <h5>Description</h5>
//         <textarea className="form-controls" rows="10" placeholder="MESSAGE" name="message" required></textarea>
//         <div>
//           <h5>Select Event Date</h5>
//         {/* <label for="birthday">Select Event Date</label> */}
//   <input type="date" id="birthday" name="birthday" />
//   </div>
//         {/* <div className="row">
//         <div classNameName="col-sm-2 col-md-2">
                                 
//                     <select id="timing" name="timing" className="" >
//                         <option value="12:00 AM">12:00 AM</option>
//                         <option value="1:00 AM">1:00 AM</option>
//                         <option value="2:00 AM">2:00 AM</option>
//                         <option value="3:00 AM">3:00 AM</option>
//                         <option value="4:00 AM">4:00 AM</option>
//                         <option value="5:00 AM">5:00 AM</option>
//                         <option value="6:00 AM">6:00 AM</option>
//                         <option value="7:00 AM">7:00 AM</option>
//                         <option value="8:00 AM">8:00 AM</option>
//                         <option value="9:00 AM">9:00 AM</option>
//                         <option value="10:00 AM">10:00 AM</option>
//                         <option value="11:00 M">11:00 AM</option>
//                         <option value="12:00 PM">12:00 PM</option>
//                         <option value="1:00 PM">1:00 PM</option>
//                         <option value="2:00 PM">2:00 PM</option>
//                         <option value="3:00 PM">3:00 PM</option>
//                         <option value="4:00 PM">4:00 PM</option>
//                         <option value="5:00 PM">5:00 PM</option>
//                         <option value="6:00 PM">6:00 PM</option>
//                         <option value="7:00 PM">7:00 PM</option>
//                         <option value="8:00 PM">8:00 PM</option>
//                         <option value="9:00 PM">9:00 PM</option>
//                         <option value="10:00 PM">10:00 PM</option>
//                         <option value="11:00 PM">11:00 PM</option>
//                     </select>
//                     <select id="timing" name="timing" class="ms-3">
//                         <option value="12:00 AM">12:00 AM</option>
//                         <option value="1:00 AM">1:00 AM</option>
//                         <option value="2:00 AM">2:00 AM</option>
//                         <option value="3:00 AM">3:00 AM</option>
//                         <option value="4:00 AM">4:00 AM</option>
//                         <option value="5:00 AM">5:00 AM</option>
//                         <option value="6:00 AM">6:00 AM</option>
//                         <option value="7:00 AM">7:00 AM</option>
//                         <option value="8:00 AM">8:00 AM</option>
//                         <option value="9:00 AM">9:00 AM</option>
//                         <option value="10:00 AM">10:00 AM</option>
//                         <option value="11:00 M">11:00 AM</option>
//                         <option value="12:00 PM">12:00 PM</option>
//                         <option value="1:00 PM">1:00 PM</option>
//                         <option value="2:00 PM">2:00 PM</option>
//                         <option value="3:00 PM">3:00 PM</option>
//                         <option value="4:00 PM">4:00 PM</option>
//                         <option value="5:00 PM">5:00 PM</option>
//                         <option value="6:00 PM">6:00 PM</option>
//                         <option value="7:00 PM">7:00 PM</option>
//                         <option value="8:00 PM">8:00 PM</option>
//                         <option value="9:00 PM">9:00 PM</option>
//                         <option value="10:00 PM">10:00 PM</option>
//                         <option value="11:00 PM">11:00 PM</option>
//                     </select>
//                 </div>

//                 </div> */}
//                 <div>
//         <button class="btn btn-primary  send-button" id="submit" type="submit" value="SEND">
//           {/* <div class="alt-send-button"> */}
//             <i class="fa fa-paper-plane"></i><span class="send-text">SEND</span>
//           {/* </div> */}
        
//         </button>
//         </div>
        
//       </form>
      
//       </div>
//       </section>

//         );
// };








class EmailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name:'',
      email: '',
      message: '',
      theme:'',
      mailing:''
    }
  }
  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST",
      url:"http://localhost:5400/send",
      data:  this.state
    }).then((response)=>{
      if (response.data.status === 'success') {
        alert("Message Sent.");
        this.resetForm()
      } else if (response.data.status === 'fail') {
        alert("Message failed to send.")
      }
    })
  }
  resetForm(){
    this.setState({name: '', email: '', message: '', theme: '',mailing: ''})
  }
  render() {
    return(
      // <div classNameName="App">
      

       <section id="contact">
  

 <div className="contact-wrapper mb-3">
        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
          {/* <div classNameName="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" classNameName="form-control" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
          </div>
          <div classNameName="form-group">
              <label htmlFor="exampleInputEmail1">Email address</label>
              <input type="email" classNameName="form-control" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
          </div>
          <div classNameName="form-group">
              <label htmlFor="message">Message</label>
              <textarea classNameName="form-control" rows="5" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
          </div>
          <button type="submit" classNameName="btn btn-primary">Submit</button> */}






   {/* <div className="form-group">     */}
        {/* <div className="col-sm-12">
           <input type="text" className="form-controls" id="name" placeholder="NAME" name="name" value="" required />

         </div> */}

      {/* </div> */}


      <div className="eventdate">

<h6>Email</h6>
{/* <input type="text" className="" id="emailsubject" value={this.state.emailsubject} onChange={this.onNameChange.bind(this)} required/> */}
<input type="text" className="form-control form-control-sm" name="mailing" id="mailing" value={this.state.mailing} onChange={this.onMailingChange.bind(this)} required />
</div>


      
      <div className="eventdate">

        <h6>Email Subject</h6>
        {/* <input type="text" className="" id="emailsubject" value={this.state.emailsubject} onChange={this.onNameChange.bind(this)} required/> */}
        <input type="text" className="form-control form-control-sm" name="theme" id="theme" value={this.state.theme} onChange={this.onThemeChange.bind(this)} required />
      </div>

      <div className="eventdate">

<h6>Select Event Date</h6>

{/* <label for="birthday">Select Event Date</label> */}

<input type="date" className="form-control form-control-sm" name="birthday" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} required/>

</div>



<div className="eventdate" >

<h6>Select Event Time</h6>




{/* <label for="birthday">Select Event Date</label> */}

<input type="time" className="form-control  form-control-sm" name="birthday" id="email" value={this.state.email} onChange={this.onEmailChange.bind(this)} required/>

</div>
 
{/* <div className="eventime">
<h5>Select Event Time</h5>
  <input type="time" id="times" value={this.state.times} onChange={this.onTimesChange.bind(this)} />
</div> */}
       {/* <div className="form-group">

         <div className="col-sm-12">

                  <input type="email" className="form-controls" id="email" placeholder="EMAIL" name="email" value="" required />

                          </div>

       </div> */}

       <h6>Description</h6>

       <textarea className="form-controls" rows="10" placeholder="MESSAGE" name="message" id="message" value={this.state.message} onChange={this.onMessageChange.bind(this)} required></textarea>

              <div >

      <button className="btn btn-md btn-danger send-button" id="submit" type="submit" value="SEND">
           {/* <div className="alt-send-button"> */}
           {/* <i className="fa fa-paper-plane"></i> */}
           <span className="send-text">Send</span>
          {/* </div> */}
        
       </button>
        </div>







        </form>
        </div>
        </section>
     
    );
  }
  onNameChange(event) {
    this.setState({name: event.target.value})
  }
  onEmailChange(event) {
    this.setState({email: event.target.value})
  }
  onMessageChange(event) {
    this.setState({message: event.target.value})
  }
  onThemeChange(event) {
    this.setState({theme: event.target.value})
  }
  onMailingChange(event) {
    this.setState({mailing: event.target.value})
  }
  
}

















export default EmailPage;