import React, { useState } from 'react';
import "./HomePage.css";



import of1 from "../images/of1.avif";
import of2 from "../images/of2.avif";

// import image13 from "../images/image13.jpg";



import about from "../images/about.jpg"

import accenture from "../images/accenture.png";
import cognizant from "../images/cognizent.png";
import wipro from "../images/wipro.png";
import tcs from "../images/tcslogo.png"

import testimonial1 from "../images/tm1.jpg";
import testimonial2 from "../images/tm2.jpg";
import testimonial3 from "../images/tm3.jpg";
import testimonial4 from "../images/tm4.jpg";
import testimonial5 from "../images/tm5.jpg";
import testimonial6 from "../images/tm6.jpg";
import ServicesPage from './ServicesPage';

import ContactUsPage from './ContactUsPage';

import EventList from './EventList';
import Team from './Team';

import Testimonials from './Testimonials';


const HomePage = () => {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("http://localhost:5400/api/submit-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Message submitted successfully");
        alert("Message submitted successfully");
        // You can add code here to show a success message or redirect to a thank you page.
      } else {
        console.error("Message submission failed");
        alert("Message submission failed")
        // Handle errors, e.g., display an error message to the user.
      }

    } catch (error) {

      console.error("An error occurred:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>

     
      {/* Get in Touch modal box for carousal */}
      
   
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title">Contact Us</h2>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form id="contact-form" className="form" role="form" onSubmit={handleSubmit}>
              <div className="mb-3">           
                <input type="text" className="form-control" id="name" name="name" placeholder="Enter your full name" tabIndex="1" 
                  required onChange={handleChange}/>
                <div className="text-danger" id="er-name"></div>
              </div>
              <div className="mb-3">
                <input type="email" className="form-control" id="email" name="email" placeholder="Enter your email" tabIndex="2"
                  required onChange={handleChange}/>
                <div className="text-danger" id="er-email"></div>
              </div>
              <div className="mb-3">           
                <input type="tel" className="form-control" id="phone" name="phone" placeholder="Enter your mobile number" tabIndex="3"
                  required onChange={handleChange}/>
                <div className="text-danger" id="er-phone"></div>
              </div>
              <div className="mb-3">
                <textarea rows="5" className="form-control" id="message" name="message" placeholder="Message..." tabIndex="4" required onChange={handleChange}></textarea>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-sm btn-success ">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>

      {/* carouselExample */}
      <section className="mt-2">
        <div className="container-fluid">
          <div id="carouselExample" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner text-center">
              <div className="carousel-item active" data-bs-interval="10000">
                <img src={of1} className="d-block w-100" alt="Image 1" />

                <div className="carousel-caption">
                  <h3 className="hed">Experts</h3>
                  <p className="txt-clr">Amplify your reach by registering youget noticed by donors, philanthropic foundations, and organizations</p>
                  <p className="txt-clr">Register your organization so that the world of the development stner with you or fund your initiatives & services</p>
                  
                  <button type="button" className="btn btn-danger btn-sm px-3 me-2 mt-5" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Get in Touch
                  </button>
                </div>
              </div>
              <div className="carousel-item" data-bs-interval="10000">
                <img src={of2} className="d-block w-100" alt="Image 2" />
                <div className="carousel-caption">
                  <h3 className="hed">Organization</h3>
                  <p className="txt-clr">Amplify your reach by registering yourself as an expert from the development and organizations</p>
                  <p className="txt-clr">Register your organization so that the world of the development sector knows your good work  & services</p>
                  <button type="button" className="btn btn-danger btn-sm px-3 me-2  mt-5" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Get in Touch
                  </button>
                </div>
              </div>
              <div className="carousel-item" data-bs-interval="10000">
                <img src={of2} className="d-block w-100" alt="Image 3" />
                <div className="carousel-caption">
                  <h3 className="hed">About</h3>
                  <p className="txt-clr">Amplify your reach by registering yourself as an expert from the development and organizations</p>
                  <p className="txt-clr">Register your organization so that the world of the development sector knows your good work  & services</p>
                  <button type="button" className="btn btn-danger btn-sm px-3 me-2 mt-5" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Get in Touch
                  </button>
                </div>
              </div>
            </div>
            {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
            
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
            
            </button> */}
  {/* <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button> */}
          </div>
        </div>
      </section>
      {/* Experts section */}
      <div className="ps-3 pe-3">
        <section className="mt-4 ">
          <div>
            <h3 className="hed">Expert Categories</h3>
          </div>
          <div className="container-fluid "  style={{ overflow: 'hidden' }}>
            <div className="image-slider">
              <div className="slide-track mt-3">
                <div className="slider">
                  <div className="container ">
                    <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#df9cf0 0%,#b7e6ee 100%)' }} >
                        <i className="fa-solid fa-vial-virus fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                            <h3 className="card-title text-center">Science & Research</h3>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 20+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 145+ Events</p>
                        </div>
                        {/* <div className=" text-end ">
                          
                            <button className="btn btn-sm btn-danger px-3 me-2 mb-2">More..</button>
                        </div> */}
                    </div>
                </div>
                </div>
                <div className="slider">
                  <div className="container ">
                    <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#8de9d5 0%,#adcf9a 100%)' }}>
                        <i className="fa-solid fa-hospital fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                            <h3 className="card-title text-center ">Health Care</h3>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 46+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 126+ Events</p>
                        </div>
                        <div className=" text-end ">
                          
                              
                          {/* <button type="button" className="btn btn-sm btn-danger mb-2 me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            More..
                          </button> */}
                        </div>
                    </div>
                </div>
                </div>
                <div className="slider" >
                  <div className="container ">
                    <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#4a9ea1 0%,#cdf8fd 100%)' }}>
                        <i className="fa-solid fa-computer fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                            <h3 className="card-title text-center ">IT & Software</h3>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 106+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 256+ Events</p>
                        </div>
                        <div className=" text-end ">
                          
                          {/* <button type="button" className="btn btn-sm btn-danger mb-2 me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            More..
                          </button> */}
                        </div>
                    </div>
                </div>
                </div>
                <div className="slider">
                  <div className="container ">
                    <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#f77cec 0%,#dfebc4 100%)' }} >
                        <i className="fa-solid fa-user-gear fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                            <h3 className="card-title text-center ">Management</h3>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 50+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 260+ Events</p>
                        </div>
                        <div className="text-end ">
                          {/* <button type="button" className="btn btn-sm btn-danger mb-2 me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            More..
                          </button> */}
                        </div>
                    </div>
                </div>
                </div>
                <div className="slider">
                  <div className="container ">
                    <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#1480e6 0%,#e4eec2 100%)' }}>
                        <i className="fa-solid fa-person-chalkboard fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                            <h3 className="card-title text-center ">Teaching</h3>
                          <p ><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 96+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 143+ Events</p>
                        </div>
                        <div className=" text-end ">
                          
                          {/* <button type="button" className="btn btn-sm btn-danger mb-2 me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            More..
                          </button> */}
                        </div>
                    </div>
                </div>
                </div>
                <div className="slider">
                  <div className="container ">
                    <div className="card shadow" style={{width: '20rem',backgroundImage: 'linear-gradient(150deg,#c7e4b0 0%,#e7b1f1 100%)'}}>
                        <i className="fa-solid fa-briefcase fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                            <h3 className="card-title text-center ">Business</h3>
                          <p ><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 56+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 325+ Events</p>
                        </div>
                        <div className=" text-end ">
                          
                          {/* <button type="button" className="btn btn-sm btn-danger mb-2 me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            More..
                          </button> */}
                        </div>
                    </div>
                </div>
                </div>
                <div className="slider">
                  <div className="container ">
                    <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#72dce4 0%,#cccabe 100%)' }} >
                        <i className="fa-solid fa-hand-fist fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                            <h3 className="card-title text-center ">Political</h3>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 50+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 208+ Events</p>
                        </div>
                        <div className=" text-end ">
                          
                          {/* <button type="button" className="btn btn-sm btn-danger mb-2 me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            More..
                          </button> */}
                        </div>
                    </div>
                </div>
                </div>
                <div className="slider">
                  <div className="container ">
                    <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#f8ad7b 0%,#bbc999 100%)' }} >
                        <i className="fa-solid fa-palette fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                          <h3 className="card-title text-center ">Arts </h3>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 86+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 186+ Events</p>
                        </div>
                        <div className=" text-end ">
                          
                          {/* <button type="button" className="btn btn-sm btn-danger mb-2 me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            More..
                          </button> */}
                        </div>
                    </div>
                </div>
                </div>
                <div className="slider">
                  <div className="container ">
                    <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#c4e47a 0%,hsl(219, 63%, 74%) 100%)' }} >
                        <i className="fa-solid fa-calculator fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                            <h3 className="card-title text-center ">Accounting</h3>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 64+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 167+ Events</p>
                        </div>
                        <div className=" text-end ">
                          
                          {/* <button type="button" className="btn btn-sm btn-danger mb-2 me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            More..
                          </button> */}
                        </div>
                    </div>
                </div>
                </div>
                <div className="slider">
                  <div className="container ">
                    <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#df9cf0 0%,#b7e6ee 100%)' }} >
                        <i className="fa-solid fa-vial-virus fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                            <h3 className="card-title text-center">Science & Research</h3>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 20+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 145+ Events</p>
                        </div>
                        <div className=" text-end ">
                          
                            {/* <button className="btn btn-sm btn-danger mb-2 me-2">More..</button> */}
                        </div>
                    </div>
                </div>
                </div>
                <div className="slider">
                  <div className="container ">
                    <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#eeb12e 0%,#f5dd91 100%)' }}>
                        <i className="fa-solid fa-hospital fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                            <h3 className="card-title text-center ">Health Care</h3>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 46+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 126+ Events</p>
                        </div>
                        <div className=" text-end ">
                          
                            
                          {/* <button type="button" className="btn btn-sm btn-danger mb-2 me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            More..
                          </button> */}
                        </div>
                    </div>
                </div>
                </div>
                <div className="slider" >
                  <div className="container ">
                    <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg, #4a9ea1 0%, #cdf8fd 100%)' }} >
                        <i className="fa-solid fa-computer fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                            <h3 className="card-title text-center ">IT & Software</h3>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 106+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 256+ Events</p>
                        </div>
                        <div className=" text-end ">
                          
                          {/* <button type="button" className="btn btn-sm btn-danger mb-2 me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            More..
                          </button> */}
                        </div>
                    </div>
                </div>
                </div>
                <div className="slider">
                  <div className="container ">
                    <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#943784 0%,#d072d8 100%)' }} >
                        <i className="fa-solid fa-user-gear fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                            <h3 className="card-title text-center ">Management</h3>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 50+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 2030+ Quetions</p>
                        </div>
                        <div className="text-end ">
                          
                          {/* <button type="button" className="btn btn-sm btn-danger mb-2 me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            More..
                          </button> */}
                        </div>
                    </div>
                </div>
                </div>
                <div className="slider">
                  <div className="container ">
                    <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#e4f123 0%,#d6eb7c 100%)' }} >
                        <i className="fa-solid fa-person-chalkboard fa-4x card-img-top p-3 m-2 text-center"></i>
              
                        <div className="card-body">
                            <h3 className="card-title text-center ">Teaching</h3>
                          <p ><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 96+ Experts</p>
                          <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 143+ Events</p>
                        </div>
                        <div className=" text-end ">
                          
                          {/* <button type="button" className="btn btn-sm btn-danger mb-2 me-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            More..
                          </button> */}
                        </div>
                    </div>
                </div>
                </div>
              
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* organization profiles */}
      <div className="ps-3 pe-3">
      <section className="mt-4">
        <div>
        <h3 className="hed" >Organization Categories</h3>
        </div>
        <div className="container-fluid" style={{ overflow: 'hidden' }}>
        <div className="image-slider">
        <div className="org-slide-track mt-3">
        <div className="slider">
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#df9cf0 0%,#b7e6ee 100%)' }}>
        <i className="fa-solid fa-palette fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">Arts & Science </h3>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 86+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 186+ Events</p>
        </div>
        <div className=" text-end ">

        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2 " data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        <div className="slider">
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#a9f1e2 0%,#b4d4a1 100%)' }} >
        <i className="fa-solid fa-stethoscope fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">Medical</h3>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 69+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 257+ Events</p>
        </div>
        <div className=" text-end ">
        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        <div className="slider" >
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#4a9ea1 0%,#cdf8fd 100%)' }}>
        <i className="fa-solid fa-building-columns fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">University</h3>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 25+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 185+ Events</p>
        </div>
        <div className=" text-end ">

        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        <div className="slider">
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#1480e6 0%,#e4eec2 100%)' }} >
        <i className="fa-brands fa-microsoft fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">Software Company</h3>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 16+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 43+ Events</p>
        </div>
        <div className="text-end ">
        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        <div className="slider">
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,hsl(64, 70%, 82%) 0%,#d6eb7c 100%)' }} >
        <i className="fa-solid fa-calculator fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">Financial Institute</h3>
        <p ><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 50+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 2030+ Quetions</p>
        </div>
        <div className=" text-end ">
        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        <div className="slider">
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#e99378 0%,#a9a5c9 100%)' }}>
        <i className="fa-solid fa-building fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">Consulting Form</h3>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 50+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 2030+ Quetions</p>
        </div>
        <div className=" text-end ">
        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        <div className="slider">
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#bf58e7 0%,#b6c09d 100%)' }}>
        <i className="fa-solid fa-building fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">Engineering</h3>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 92+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 200+ Events</p>
        </div>
        <div className=" text-end ">
        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        <div className="slider" >
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#90d7da 0%,#7fa38b 100%)' }}>
        <i className="fa-solid fa-building-columns fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">Industry</h3>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 25+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 185+ Events</p>
        </div>
        <div className=" text-end ">

        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>

        </div>
        <div className="slider" >
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#dff179 0%,#7fa8ad 100%)' }}>
        <i className="fa-solid fa-list fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">Others</h3>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 52+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 175+ Events</p>
        </div>
        <div className=" text-end ">

        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        <div className="slider">
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#abd8ec 0%,#78815f 100%)' }}>
        <i className="fa-solid fa-palette fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">Arts & Science </h3>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 86+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 186+ Events</p>
        </div>
        <div className=" text-end ">

        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        <div className="slider">
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#eccb84 0%,#b6ecc2 100%)' }}>
        <i className="fa-solid fa-hospital fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">Medical</h3>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 69+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 257+ Events</p>
        </div>
        <div className=" text-end ">
        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        <div className="slider" >
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#5adf96 0%,#cdf8fd 100%)' }} >
        <i className="fa-solid fa-building-columns fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">University</h3>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 25+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 185+ Events</p>
        </div>
        <div className=" text-end ">

        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        <div className="slider">
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#afb484 0%,#d072d8 100%)' }} >
        <i className="fa-brands fa-microsoft fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">Software Company</h3>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 16+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 43+ Events</p>
        </div>
        <div className="text-end ">
        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        <div className="slider">
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#d4da86 0%,#545c31 100%)' }}>
        <i className="fa-solid fa-calculator fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">Account Form</h3>
        <p ><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 50+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 2030+ Quetions</p>
        </div>
        <div className=" text-end ">
        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        <div className="slider">
        <div className="container ">
        <div className="card shadow" style={{ width: '20rem', backgroundImage: 'linear-gradient(150deg,#f378d4 0%,#7e9fc5 100%)' }} >
        <i className="fa-solid fa-building fa-4x card-img-top p-3 m-2 text-center"></i>

        <div className="card-body">
        <h3 className="card-title text-center ">Consulting Form</h3>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 50+ Experts</p>
        <p><i className="fa-regular fa-circle-dot fa-sm"></i>&nbsp; 2030+ Quetions</p>
        </div>
        <div className=" text-end ">
        {/* <button type="button" className="btn btn-sm btn-danger px-3 me-2 mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
        More..
        </button> */}
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
      </section>
      </div>
      {/* our servies */}
      
      <ServicesPage></ServicesPage>
      {/* How it works */}
      <div className='ps-3 pe-3'>
        <section>
        <div className=" mt-4">
        <div>
        <h3 className="hed ">How it helps</h3>
        </div>
        <div className="container-fluid mt-4 stepper">
        <div className="row">
          <div className="col-md-6 mt-4">
            <h3 className="black-text m-3">Expert flow</h3>
            <ul className="timeline">
            <li className="timeline-item">
            <div className="timeline-card">
            <h3>Step1</h3>
            <p>some content about the step1</p>
            </div>
            </li>
            <li className="timeline-item">
            <div className="timeline-card">
            <h3>Step2</h3>
            <p>some content about the step2</p>
            </div>
            </li>
            <li className="timeline-item">
            <div className="timeline-card">
            <h3>Step3</h3>
            <p>some content about the step3</p>
            </div>
            </li>
            <li className="timeline-item">
            <div className="timeline-card">
            <h3>Step4</h3>
            <p>some content about the step4</p>
            </div>
            </li>

            </ul>
          </div>
          <div className="col-md-6 mt-4">
            <h3 className="black-text m-3">Organization flow</h3>
            <ul className="timeline">
            <li className="timeline-item">
            <div className="timeline-card">
            <h3>Step1</h3>
            <p>some content about the step1</p>
            </div>
            </li>
            <li className="timeline-item">
            <div className="timeline-card">
            <h3>Step2</h3>
            <p>some content about the step2</p>
            </div>
            </li>
            <li className="timeline-item">
            <div className="timeline-card">
            <h3>Step3</h3>
            <p>some content about the step3</p>
            </div>
            </li>
            <li className="timeline-item">
            <div className="timeline-card">
            <h3>Step4</h3>
            <p>some content about the step4</p>
            </div>
            </li>

            </ul>
          </div>
        </div>
        </div>
`
        </div>
        </section>
      </div>
      {/* Key highlights */}
      <div className='ps-3 pe-3'>
      <section className="mt-5">
      <div className="container-fluid ">
        <div className="row border ">
        <div className="col-md-4 border-end">
        <div className="row kh2">
        <p className="p-4  hed fs-2 bg-info" style={{ borderBottomRightRadius: '30px' }} >Key Highlights</p>
        </div>
        <div className="row kh2">
        <div className="col-md-6  border kbg" >
        <div className="d-flex justify-content-center m-3 "><i className="fa-solid fa-chalkboard-user fa-3x khp "></i></div>
        <p className="fw-bold fs-2 text-center">1200</p>
        <p className="text-center fw-normal">Experts</p>
        </div>
        <div className="col-md-6 border-top border-bottom kbg">
        <div className="d-flex justify-content-center m-3"> <i className="fa-solid fa-building-ngo fa-3x khp"></i></div>
        <p className="fw-bold fs-2 text-center">349</p>
        <p className="text-center fw-normal">Organizations</p>
        </div>
        </div>
        </div>
        <div className="col-md-4 " >
        <div className="row border kh" >
        <div className="col-md-6  d-block kbg " >
        <div className="d-flex justify-content-center m-3"><i className="fa-solid fa-calendar-days fa-3x pt-5 mt-5"></i></div>
        <p className="fw-bold fs-2 text-center">364</p>
        <p className="text-center fw-normal">Number of Events </p>
        </div>
        <div className="col-md-6  kh">
        <div className="row d-block border kbg kh2">
        <div className="d-flex justify-content-center pt-4"><i className="fa-regular fa-newspaper fa-3x"></i></div>
        <p className="fw-bold fs-2 text-center">NAAC</p>
        <p className="text-center fw-normal">Accredited</p>
        </div>
        <div className="row kbg border-end kh2">
        <div className="d-flex justify-content-center  "  ><i className="fa-solid fa-building-columns fa-3x mt-4"></i></div>
        <p className="fw-bold fs-2 text-center">29</p>
        <p className="text-center fw-normal">Universities</p>
        </div>
        </div>
        </div>
        </div>
        <div className="col-md-4 kh" >
        <div className="row border kbg  kh2">
        <div className="col-md-4 p-4">
        <div className="d-flex justify-content-center m-3"><i className="fa-solid fa-earth-americas fa-4x mt-3 "></i></div>

        </div>
        <div className="col-md-8 p-4 ">
        <p className="fw-bold fs-2 text-center khp2 ">12</p>
        <p className="text-center fw-normal">Global Locations</p>
        </div>
        </div>

        <div className="row border-bottom border-start kbg kh2" >
        <div className="col-md-4 p-4 ">
        <div className="d-flex justify-content-center m-3"><i className="fa-solid fa-venus-mars  fa-4x mt-3"></i></div>

        </div>
        <div className="col-md-8 p-3 ">
        <p className="fw-bold fs-2 text-center khp3">12:5</p>
        <p className="text-center ">Male-to-Female Ratio </p>
        </div>
        </div>
        </div>
        </div>
      </div>
      </section>
      </div>
      {/* Events */}
      <EventList></EventList>
      {/* About */}
      <div className='ps-3 pe-3'>
      <section className=" mt-4" id="about">
      <div>
      <h3 className="hed ">About</h3>
      </div>
      <div className="container-fluid mt-3">
      <div className="row">
      <div className="col-md-5 zoom1">
      <img src={about} className="w-100 zoom-image" alt="about"/>
      </div>
      <div className="col-md-7 p-4 ">
      <p className="fs-6" style={{ fontFamily: 'sans-serif' }}>some matter about the company Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
      </div>
      </div>
      </div>
      </section>
      </div>
      {/* Leadership team */}
      
      

      {/* Contact us */}
      <ContactUsPage></ContactUsPage>
      
      {/*Team*/}
      <Team></Team>
      {/* our partners */}
      <div className='ps-3 pe-3'>
        <section className="mt-4">
        <div>
        <h3 className="hed">Our Partners</h3>
        </div>
        <div className="container-fluid" style={{ overflow: 'hidden' }}>
        <div className="image-slider">
          <div className="slide-track mt-3">
          <div className="partners-slider">
            <img src={accenture} alt="accenture"/>
          </div>
          <div className="partners-slider">
            <img src={cognizant} alt="cognizant"/>
          </div>
          <div className="partners-slider">
            <img src={wipro} alt="wipro"/>
          </div>
          <div className="partners-slider">
            <img src={tcs} alt="tcs"/>
          </div>
          <div className="partners-slider">
            <img src={accenture} alt="accenture"/>
          </div>
          <div className="partners-slider">
            <img src={cognizant} alt="cognizant"/>
          </div>
          <div className="partners-slider">
            <img src={wipro} alt="wipro"/>
          </div>
          <div className="partners-slider">
            <img src={tcs} alt="tcs"/>
          </div>
          <div className="partners-slider">
            <img src={accenture} alt="accenture"/>
          </div>
          <div className="partners-slider">
            <img src={cognizant} alt="cognizant"/>
          </div>
          <div className="partners-slider">
            <img src={wipro} alt="wipro"/>
          </div>
          <div className="partners-slider">
            <img src={tcs} alt="tcs"/>
          </div>
          </div>
        </div>
        </div>

        </section>
      </div>

      {/* testimonials */}
      <Testimonials></Testimonials>
      
    
    </>
  );
};

export default HomePage;

