import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faCalendarAlt,
  faPhone,
  faUserPlus,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./UserNavbar.css";
import { Link } from 'react-router-dom';
import ProfileCardList2 from './ProfileCardList2';
const UserNavbar = () => {
  return (
    <React.Fragment>
    
      {/* Navbar */}
     
      <nav className="navbar navbar-expand-lg navbar-white bg-danger pmg-fontfamily sticky-top" >
      <div className="container-fluid">
            <a className="navbar-brand" href="/">
                <span className='pmglogotext-pink pmg-main-heading'>Pink</span>
                <span className='pmglogotext-green pmg-main-heading'>Geo</span>
            </a>
            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button> */}
            <div className="d-flex justify-content-center">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item ">
              <a className="nav-link text-white" aria-current="page" href="/"><i className="fa-solid fa-house ps-2"></i> Home</a>
              </li>
              {/* <li className="nav-item">
              <a className="nav-link text-white" href="/"><i class="fa-solid fa-right-from-bracket"></i>  LogOut </a>
              </li> */}
               {/* <li className="nav-item">
              <a className="nav-link text-white" href="/events"><i class="fa-solid fa-pen-to-square"></i> EditProfile </a>
              </li> */}
              <li className="nav-item">
                <a
                    className="nav-link text-white"
                    href="/services"
                >
                    <i className="fa-solid fa-satellite-dish"></i> Services
                 </a>
              </li>

              {/* <li className="nav-item dropdown">
              <a
                className="nav-link text-white dropdown-toggle"
                href="/about"
                id="aboutDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              ><i className="fa-solid fa-address-card ps-2"></i> About </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="aboutDropdownMenuLink"
              >
                <li>
                  <a className="dropdown-item" href="#">
                    Platform
                  </a>
                </li> 
                <li>
                  <a className="dropdown-item" href="#">
                    Leadership Team
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Blog
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    News
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    FAQ
                  </a>
                </li>
              </ul>
              </li> */}

              <li className="nav-item dropdown">
              <a
                className="nav-link text-white dropdown-toggle"
                href="/contact"
                id="contactDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa-solid fa-phone ps-2"></i> Contact Us</a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="contactDropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item" href="/message">
                      Message
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/EmailPage">
                      Email
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/livechat">
                      Live Chat
                    </a>
                  </li>
                
                </ul>
              </li>
              </ul>
            </div>
        
            <div className='d-flex justify-content-end'>
              {/* <Link to="/login" className="custom-link"> */}
                {/* <button className="custom-btn btn btn-sm  btn-outline-light  mx-2">
                  <i className="fa-solid fa-right-to-bracket"></i>
                  <span className="btn-text"> Login</span>
                </button> */}



<li className="nav-item dropdown  rounded-circle1">
              <a
                className="nav-link text-white dropdown-toggle"
                href="/contact"
                id="contactDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
            src="https://mdbootstrap.com/img/Photos/Avatars/avatar-5.jpg"
            class="rounded-circle z-depth-0"
            alt="avatar image"
            height="38"
          />
            </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="contactDropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item" href="/getintouch">
                      Edit Profile
                    </a>
                  </li>
                
                  <li>
                  {/* <Link to="/"> */}
                   
                    <a className="dropdown-item" href="/login">
                    
                   Logout
                  
                    </a>
                    {/* </Link>  */}
                  </li>
                     

                  {/* <li>
                    <a className="dropdown-item" href="#">
                      Live Chat
                    </a>
                  </li> */}
                
                </ul>
              </li>
              {/* </Link> */}
              {/* <Link to="/register" className="custom-link"> */}
                {/* <button className="custom-btn btn btn-sm btn-outline-light">
                  <i className="fa-solid fa-user"></i>
                  <span className="btn-text"> Register</span>
                </button> */}
              {/* </Link> */}
            </div>
           
          </div> 
        </nav> 

        <ProfileCardList2></ProfileCardList2>
   
    </React.Fragment>
    )
}

export default UserNavbar ;
