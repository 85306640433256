import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './LoginPage1.css';

const LoginPage1 = () => {
  return (
    <div className='pmg-fontfamily' style={{ backgroundColor: 'brown', position:'relative',minHeight: '30vh', marginTop: '30px', paddingBottom: '40px',paddingTop:'25px'}}>
      <div className="d-flex flex-row justify-content-center mb-4">
        <FontAwesomeIcon icon={faLock} size="3x" color="white" />
      </div>
      <p className='text-center text-white'>If you don't have an account, please register</p>
      <h1 className="text-white text-center mb-4">Create an Account</h1>
      <div className="row justify-content-center">
        <div className="col-md-3 mt-2 d-flex align-items-center justify-content-center">
          <Link to="/registerasorganization">
            <img src="https://i.ibb.co/ZJSHf9p/wepik-export-20230726104104-Gw-QI.png"  alt="Social Network" width="150" height="150" style={{ borderRadius: '50%' , marginRight: '25px' }}  />
          </Link>
        </div>

        <div className="col-md-3 mt-2 d-flex align-items-center justify-content-center">
          <Link to="/registerasexpert">
            <img src="https://as1.ftcdn.net/v2/jpg/02/31/44/46/1000_F_231444639_uIKYySnDoEysknrKflFxrkZ8ZqjXvbpf.jpg"  alt="Experts Network" width="150" height="150" style={{ borderRadius: '50%' }} />           
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage1;
