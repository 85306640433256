// src/MessagePage.js

import React, { useState } from "react";
import "./MessagePage.css";

const MessagePage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("http://localhost:5400/api/submit-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("Message submitted successfully");
        // You can add code here to show a success message or redirect to a thank you page.
      } else {
        console.error("Message submission failed");
        // Handle errors, e.g., display an error message to the user.
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="container mt-2 mb-5 pmg-fontfamily">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card mt-4" style={{ backgroundColor: "#c0a31f", color: "#fff" }}>
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Contact Us</h2>
              <form id="contact-form" className="form" role="form" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Enter your full name"
                    tabIndex="1"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    tabIndex="2"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    name="phone"
                    placeholder="Enter your mobile number"
                    tabIndex="3"
                    required
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    rows="5"
                    className="form-control"
                    id="message"
                    name="message"
                    placeholder="Message..."
                    tabIndex="4"
                    required
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-success btn-sm">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagePage;
