// import React, { useState, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPaperPlane, faMessage, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// import "./Forgotpassword.css";

// function Forgotpassword() {
//     const [isOTPEnabled, setIsOTPEnabled] = useState(false);
//     const [isPasswordEnabled, setIsPasswordEnabled] = useState(false);
//     const [showPassword, setShowPassword] = useState(false);
//     const [showReenteredPassword, setShowReenteredPassword] = useState(false);

//     const [phone, setPhone] = useState(""); // Initialize phone with an empty string
//     const [otp, setOtp] = useState("");
//     const [user, setUser] = useState(null);

//     const [isOTPVerified, setIsOTPVerified] = useState(false);
//     const [showOTP, setShowOTP] = useState(false);

//     const [formData, setFormData] = useState({
//         mobileNumber: '',
//         otp: '',
//         newPassword: '',
//         reenteredPassword: ''
//     });
//     const [formErrors, setFormErrors] = useState({});
//     const [isSubmitting, setIsSubmitting] = useState(false);
//     const [passwordError, setPasswordError] = useState("");
//     const [confirmPasswordError, setConfirmPasswordError] = useState("");

//     useEffect(() => {
//         // Implement form validation here and update formErrors state
//         const errors = {};

//         // Validate Mobile Number
//         if (isOTPEnabled && formData.mobileNumber.length !== 10) {
//             errors.mobileNumber = 'Mobile number must be 10 digits.';
//         }

//         // Validate OTP
//         if (isOTPEnabled && formData.otp.length !== 6) {
//             errors.otp = 'OTP must be exactly 6 digits.';
//         }

//         // Validate New Password
//         if (isPasswordEnabled) {
//           // Check if the new password is a 6-digit PIN
//           const pinPattern = /^\d{6}$/;
//           if (!pinPattern.test(formData.newPassword)) {
//               errors.newPassword = 'Password must be a 6-digit PIN.';
//           }
//         }

//         if (isPasswordEnabled && formData.newPassword.length < 6) {
//             errors.newPassword = 'Password must be at least 6 characters long.';
//         }
        
      
//         // Validate Reentered Password
//         if (isPasswordEnabled && formData.newPassword !== formData.reenteredPassword) {
//             errors.reenteredPassword = 'Passwords do not match.';
//         }

//         setFormErrors(errors);
//     }, [formData, isOTPEnabled, isPasswordEnabled]);

//     function handleClick2() {
//         // Implement logic to send OTP to the provided mobile number here
//         setIsOTPEnabled(true);
//     }

//     function handleOTPSubmit() {
//         // Implement logic to verify OTP here
//         setIsPasswordEnabled(true);
//     }

//     function handleSubmit(event) {
//         event.preventDefault();

//         // Check if there are any form errors before submitting
//         if (Object.keys(formErrors).length === 0) {
//             // Implement logic to reset the password here
//             setIsSubmitting(true);
//         }
//     }

//     return (
//         <div className="container">
//             <div className='forgot-password-container'>
//                 <div className="forgot-password-form ">
//                     <h2>Forgot Password</h2>
//                     <form onSubmit={handleSubmit}>
//                         {/* Render form errors if there are any */}
//                         {Object.keys(formErrors).length > 0 && (
//                             <div className="alert alert-danger">
//                                 {Object.values(formErrors).map((error, index) => (
//                                     <p key={index}>{error}</p>
//                                 ))}
//                             </div>
//                         )}

//                         <div className="input-group mt-1" style={{ display: 'flex', alignItems: 'center' }}>
//                             <input
//                                 type="tel"
//                                 name="mobileNumber"
//                                 placeholder="Mobile Number"
//                                 className="form-control input-field"
//                                 style={{ height: '40px', flex: '1', marginRight: '10px' }}
//                                 value={formData.mobileNumber}
//                                 onChange={(e) => setFormData({ ...formData, mobileNumber: e.target.value })}
//                             />
//                             <button
//                                 type="button"
//                                 className="btn btn-danger submit-button"
//                                 onClick={handleClick2}
//                                 title="Send SMS"
//                                 style={{ height: '40px', alignSelf: 'stretch' }}
//                             >
//                                 <FontAwesomeIcon icon={faPaperPlane} className="icon-style" />
//                             </button>
//                         </div>
//                         {isOTPEnabled && (
//                           <div className="input-group mt-1" style={{ display: 'flex', alignItems: 'center' }}>
//                             <input
//                               type="text"
//                               name="otp"
//                               placeholder="OTP"
//                               className="form-control input-field"
//                               style={{ flex: '1', marginRight: '10px', height: '40px' }}
//                               value={formData.otp}
//                               onChange={(e) => setFormData({ ...formData, otp: e.target.value })}
//                             />
//                             <button
//                               type="button"
//                               className="btn btn-danger submit-button"
//                               onClick={handleOTPSubmit}
//                               title="Verify OTP"
//                               style={{ height: '40px', alignSelf: 'stretch' }}
//                             >
//                               <FontAwesomeIcon icon={faMessage} className="icon-style" />
//                             </button>
//                           </div>
//                         )}


//                         {isPasswordEnabled && (
//                           <>
//                             <div className="input-group mt-1" style={{ display: 'flex', alignItems: 'center' }}>
//                                 <input
//                                     type={showPassword ? "text" : "password"}
//                                     name="newPassword"
//                                     placeholder="Enter New Pin"
//                                     className="form-control input-field"
//                                     value={formData.newPassword}
//                                     onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
//                                     style={{ flex: '1', marginRight: '10px' }}
//                                 />
//                                 <button
//                                     type="button"
//                                     className="btn btn-danger submit-button inline-button"
//                                     onClick={() => setShowPassword(!showPassword)}
//                                     title={showPassword ? "Hide Password" : "Show Password"}
//                                     style={{ alignSelf: 'flex-start', height: '40px' }}
//                                 >
//                                     <FontAwesomeIcon
//                                         icon={showPassword ? faEye : faEyeSlash}
//                                         className="icon-style"
//                                     />
//                                 </button>
//                             </div>

//                             <div className="input-group mt-1" style={{ display: 'flex', alignItems: 'center' }}>
//                                 <input
//                                     type={showReenteredPassword ? "text" : "password"}
//                                     name="reenteredPassword"
//                                     placeholder="Reenter New Pin"
//                                     className="form-control input-field"
//                                     value={formData.reenteredPassword}
//                                     onChange={(e) => setFormData({ ...formData, reenteredPassword: e.target.value })}
//                                     style={{ flex: '1', marginRight: '10px' }}
//                                 />
//                                 <button
//                                     type="button"
//                                     className="btn btn-danger submit-button inline-button"
//                                     onClick={() => setShowReenteredPassword(!showReenteredPassword)}
//                                     title={showReenteredPassword ? "Hide Reentered Password" : "Show Reentered Password"}
//                                     style={{ alignSelf: 'flex-start', height: '40px' }}
//                                 >
//                                     <FontAwesomeIcon
//                                         icon={showReenteredPassword ? faEye : faEyeSlash}
//                                         className="icon-style"
//                                     />
//                                 </button>
//                             </div>
//                           </>
//                         )}


//                         <div className="text-center">
//                             <button
//                                 type="submit"
//                                 className="btn btn-danger btn-sm px-3 me-2"
//                                 disabled={!isPasswordEnabled || isSubmitting}
//                             >
//                                 {isSubmitting ? 'Submitting...' : 'Submit'}
//                             </button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Forgotpassword;


import React, { useState ,useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faMessage, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './Forgotpassword.css'
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-hot-toast";
import axios from "axios";

const ForgotPassword = () => {
  const [isOTPEnabled, setIsOTPEnabled] = useState(false);
  const [isPasswordEnabled, setIsPasswordEnabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState(""); // Initialize phone with an empty string
  const [otp, setOtp] = useState("");
  const [user, setUser] = useState(null);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [showReenteredPassword, setShowReenteredPassword] = useState(false);
  const [formData, setFormData] = useState({
    mobileNumber: '',
    otp: '',
    newPassword: '',
    reenteredPassword: ''
   });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  function sendPhoneNumberToBackend() {
    setIsPasswordEnabled(true);
    // Make an HTTP POST request to your backend API endpoint
    axios
      .post("http://localhost:5405/api/send-otp", { phone: phone, otp: otp })
      .then((response) => {
        // Handle success
        console.log(response.data);
        if (response.data.message === "OTP verification successful") {
          setIsOTPVerified(true);
          toast.success("OTP verified successfully!");
        } else {
          toast.error("OTP verification failed");
        }
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
        toast.error("Error sending phone number to the backend");
      });
  }
 
  useEffect(() => {
    // Implement form validation here and update formErrors state
    const errors = {};

    // Validate Mobile Number
    if (isOTPEnabled && formData.mobileNumber.length !== 10) {
        errors.mobileNumber = 'Mobile number must be 10 digits.';
    }

    // Validate OTP
    if (isOTPEnabled && formData.otp.length !== 6) {
        errors.otp = 'OTP must be exactly 6 digits.';
    }

    // Validate New Password
    if (isPasswordEnabled) {
      // Check if the new password is a 6-digit PIN
      const pinPattern = /^\d{6}$/;
      if (!pinPattern.test(formData.newPassword)) {
          errors.newPassword = 'Password must be a 6-digit PIN.';
      }
  }
    if (isPasswordEnabled && formData.newPassword.length < 6) {
        errors.newPassword = 'Password must be at least 6 characters long.';
    }
    
  
    // Validate Reentered Password
    if (isPasswordEnabled) {
      if (formData.newPassword !== formData.reenteredPassword) {
        errors.reenteredPassword = 'Passwords do not match.';
        setConfirmPasswordError('Passwords do not match.');
      } else {
        setConfirmPasswordError('');
      }
    }

    setFormErrors(errors);
  }, [formData, isOTPEnabled, isPasswordEnabled]);

function handleClick2() {
    // Implement logic to send OTP to the provided mobile number here
    setIsOTPEnabled(true);
}

function handleOTPSubmit() {
    // Implement logic to verify OTP here
    setIsPasswordEnabled(true);
}

function handleSubmit(event) {
  event.preventDefault();
console.log("sdfsad")
  // Check if there are any form errors before submitting
  // if (Object.keys(formErrors).length === 0) {
    // Construct the data to be sent to the server
    const data = {
      newPassword: formData.newPassword,
      reenteredPassword: formData.reenteredPassword,
      phone: phone, // Include the phone number here
    };

    // Make an HTTP POST request to your backend API endpoint
    axios
      .post("http://localhost:5400/save-password", data)
      .then((response) => {
        // Handle success, e.g., show a success message
        toast.success("Password saved successfully!");
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
        toast.error("Error saving the password.");
      });
  // }
}

  
  return (
    <div className="pmg-forgot-password-main pmg-fontfamily mt-5">
      <form className="forgot-password-form">
        <h1 className="form-heading">Forgot Password</h1>

        <div className="input-group mt-1 phone" style={{ display: 'flex', alignItems: 'center' }}>
          <PhoneInput
            defaultCountry="in" 
            value={phone}
            onChange={(value) => setPhone(value)}
            className="phone-input"
            style={{ flex: 1 , margin:0, padding: 0}} // This makes the phone input expand to fill available space
          />
          <button
            type="button"
            className="btn btn-danger submit-button"
            onClick={sendPhoneNumberToBackend}
            title="Send SMS"
            style={{ height: '34px' }}
          >
            <FontAwesomeIcon icon={faPaperPlane} className="icon-style" />
          </button>
        </div>

        <div className="input-group mt-1">
            <input
              type="text"
              placeholder="OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              OTPLength={6}
              otpType="number"
              className="form-control input-field"
            />
            <button type="button" className="btn btn-danger submit-button inline-button" onClick={sendPhoneNumberToBackend} title="Verify OTP">
              <FontAwesomeIcon icon={faMessage} className="icon-style" />
            </button>
          </div>

        {isOTPVerified && ( 
          <div>
            <div className="input-group mt-1">
              <input
                type="text"
                placeholder="Password"
                value={formData.newPassword}
                onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
                className="form-control input-field"
              />
              {passwordError && <p className="error-message">{passwordError}</p>}
              <button
                type="button"
                className="btn btn-danger submit-button inline-button"
                onClick={() => setShowPassword(!showPassword)}
                title={showPassword ? "Hide Password" : "Show Password"}
                style={{ alignSelf: 'flex-start', height: '34px' }}
              >
                <FontAwesomeIcon
                  icon={showPassword ? faEye : faEyeSlash}
                  className="icon-style"
                />
              </button>
            </div>

            <div className="input-group mt-1" style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="text"
                placeholder="Confirm Password"
                value={formData.reenteredPassword}
                onChange={(e) => setFormData({ ...formData, reenteredPassword: e.target.value })}
                className="form-control input-field"
              />
              
              <button
                type="button"
                className="btn btn-danger submit-button inline-button"
                onClick={() => setShowReenteredPassword(!showReenteredPassword)}
                title={showReenteredPassword ? "Hide Reentered Password" : "Show Reentered Password"}
                style={{ alignSelf: 'flex-start', height: '34px' }}
              >
                <FontAwesomeIcon
                  icon={showReenteredPassword ? faEye : faEyeSlash}
                  className="icon-style"
                />
              
              </button>
              
            </div>
            {confirmPasswordError && <p className="error-message"  style={{ color:'red' }}>{confirmPasswordError}</p>}
          </div>
        )}
                          
                        

        
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-danger btn-sm px-3 me-2"
            onClick={handleSubmit}
            // disabled={!isOTPVerified}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;