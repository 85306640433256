import React, { useEffect, useState } from 'react';
import "./ProfileCard.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
function ProfileCard({ name, specialization, experience, location, imagesrc, id, fullDetail }) {
  const [profileData,setProfileData]=useState()

  useEffect(() => {
    // Fetch Expert profile data from the backend API
    axios.get('http://localhost:5400/api/user-data') // Update the endpoint URL
      .then(response => {
        setProfileData(response.data);
        console.log(profileData)
      })
      .catch(error => {
        console.error('Error fetching Expert profile data:', error);
      });
  }, [profileData]);

  return (
    <div className="pmg-fontfamily col-sm-6 col-lg-6 pe-0 width">
      <div className="cards m-b-30 card-background-color">
        <div className="media">
          <div className="mt-0 d-inline-block">
            <div className=" mt-0">
              <img className="img-fluid rounded thumb-lg ms-0 me-0"
               //src="https://images.unsplash.com/photo-1544717297-fa95b6ee9643?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHx8&w=1000&q=80"
              //  src={profileData}
              src={imagesrc} 
              alt="" />
              
              {/* */}
            </div>
            <div className="d-inline expert-name d-flex mt-3 text-wrap"  style={{width:"6rem"}}>
              <h5 className="mt-0 font-18 mb-1 d-flex">{name}</h5></div>
          </div>
          <div className="media-body custom-block-info pt-0 mt-1">
            <div className="d-flex">
              <a href={`/ProfileCardsDetailsList/${id}`} className="id-fulldetails">
                <small className="small-name">{id}</small>
              </a>
            </div>
            <div className="d-flex  text-wrap"  style={{width:"6rem"}}>
              <small className="mt-2">{specialization}</small>
            </div>
            <div className="d-flex">
              <small className="small-name1 mt-2">{experience} Yrs</small>
            </div>
            <div className="d-flex">
              <small className="small-name2 mt-2">{location}</small>
            </div>
       
            <a href={`/ProfileCardsDetailsList2/${id}`} className="id-fulldetails">
            <button type="button" className="btn btn-secondary btn-sm">
            <FontAwesomeIcon icon={faArrowRight} />
            </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
