import React from 'react';
import './RegistrationPage.css';
import of1 from '../images/of1.avif';
import of2 from '../images/of2.avif';

import { Link } from 'react-router-dom';

const RegistrationPage = () => {
  return (
    <>
      <div className="container mt-5 pmg-fontfamily">
        <div className="row">
          
          <h1 className='text-center' style={{ color: 'red' }}>Welcome to PinkGeo Platform</h1>
          <div className="col-lg-12 mt-2">
            At PinkMoon Technologies, we provide business transformation powered by sound IT strategy, consulting, cutting-edge technology solutions, data warehouse & business intelligence, and innovative quality assurance techniques. Our customer-centric approach combined with domain expertise in Retail, Insurance, Healthcare, Education, and Research helps us nurture a strong partnership with our customers across the globe.
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-6">
            <div className="card" style={{backgroundImage: `url(${of1})`, height: '50vh'}}>
              <div className="card-body d-flex flex-column align-items-center justify-content-center">
                <i><h3 className="card-title text-center font-weight-bold text-red">Expert</h3></i>
                <p className="card-text text-white">
                  Amplify your reach by registering yourself as an expert from the development sector to get noticed by donors, philanthropic foundations, and organizations
                </p>
                <p className="card-text text-white">
                  Register your organization so that the world of the development sector knows your good work and is looking to partner with you or fund your initiatives & services
                </p>
                <div className="d-flex flex-row justify-content-center">
                  <div className="button-group">
                    <Link to="/registerasexpert">
                    <button className="btn btn-danger btn-sm px-3 me-2" >Register as an Expert</button>
                    </Link> 
                    <Link to="/loginasexpert">
                    <button className="btn btn-danger btn-sm px-3 me-2" style={{ marginLeft: '5px' }}>Login as an Expert</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card" style={{ backgroundImage: `url(${of2})`, height: '50vh' }}>
              <div className="card-body d-flex flex-column align-items-center justify-content-center">
                <i><h3 className="card-title text-center font-weight-bold text-red">Organization</h3></i>
                <p className="card-text text-white"> 
                  Connect and work with relevant groups and philanthropists who give me to individuals and organizations as well as development experts from various backgrounds, experiences, and origins to create a strong partnership for delivering impact in society
                </p>
                <p className="card-text text-white">
                  Register your organization so that the world of the development sector knows your good work and is looking to partner with you or fund your initiatives & services
                </p>
                <div className="d-flex flex-row justify-content-center">
                  <div className="button-group">
                    <Link to="/registerasorganization">
                    <button className="btn btn-danger btn-sm px-3 me-2">Register as an Org..</button>
                    </Link>
                    <Link to="/loginasorganization">
                      <button className="btn btn-danger btn-sm px-3 me-2" style={{ marginLeft: '5px' }}>Login as an Org..</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

</>
);
};

export default RegistrationPage;