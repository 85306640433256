import React from "react";

const TeamMember = ({ name, role, imageUrl, linkedinurl, gmailurl }) => {
  const emailLink = `mailto:${gmailurl}`;
  return (
    <div className="col-sm-6 col-lg-3 mb-4 wow zoomInLeft animated" data-wow-duration="1s" data-wow-offset="100">
      <div className="card">
        <img src={imageUrl} className="img-fluid d-block w-100 card-img-top" alt={name} />
        <div className="card-body">
          <h5>{name}</h5>
          <h7 className="text-muted">{role}</h7>
          <div className="social mt-3">
            <a  href={linkedinurl} className="btn btn-floating"><i className="fab fa-linkedin"></i></a>
            <a href={emailLink} className="btn btn-floating"><i className="fa fa-envelope"></i></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
