import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import the FontAwesomeIcon component
import { faHome,faCalendarAlt,faSatelliteDish,faAddressCard,faPhone } from '@fortawesome/free-solid-svg-icons'; // Import the specific FontAwesome icon you want to use
import { useLocation } from "react-router-dom";
const Navbar = () => {

  const location = useLocation()

  if(location.pathname === "/UserNavbar" || location.pathname === "/UserNavbar2" ) {
    return null
  }
 
  return (
    <div className="pmg-fontfamily">
      <nav className="navbar navbar-expand-lg navbar-light bg-danger sticky-top">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <span className='pmglogotext-pink pmg-main-heading'>Pink</span>
            <span className='pmglogotext-green pmg-main-heading'>Geo</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item ">
                <a className="nav-link text-white" aria-current="page" href="/">
                  <FontAwesomeIcon icon={faHome} className="fa-solid ps-2" /> Home
                </a>
              </li>
              
              <li className="nav-item">
                <a className="nav-link text-white" href="/events"> 
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-solid ps-2" /> Events 
                </a>
              </li>
              
              <li className="nav-item">
                <a
                    className="nav-link text-white"
                    href="/services"
                >
                    <FontAwesomeIcon icon={faSatelliteDish} className="fa-solid  ps-2"/> Services
                </a>
              </li>

              <li className="nav-item dropdown">
              <a
                className="nav-link text-white dropdown-toggle"
                href="/about"
                id="aboutDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              ><FontAwesomeIcon icon={faAddressCard} className="fa-solid  ps-2"/> About </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="aboutDropdownMenuLink"
              >
                <li>
                  <a className="dropdown-item" href="#">
                    Platform
                  </a>
                </li> 
                <li>
                  <a className="dropdown-item" href="/team">
                    Leadership Team
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Blog
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    News
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    FAQ
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/feedback">
                    Feedback
                  </a>
                </li>
              </ul>
              </li>

              <li className="nav-item dropdown">
              <a
                className="nav-link text-white dropdown-toggle"
                href="/contact"
                id="contactDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FontAwesomeIcon icon={faPhone} className="fa-solid ps-2"/> Contact Us</a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="contactDropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item" href="/message">
                      Message
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/EmailPage">
                      Email
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/livechat">
                      Live Chat
                    </a>
                  </li>
                
                </ul>
              </li>
              </ul>
          </div>
            <div className='d-flex justify-content-end'>
              <Link to="/login" className="custom-link">
                <button className="custom-btn btn btn-sm  btn-outline-light  mx-2" >
                  <i className="fa-solid fa-right-to-bracket"></i>
                  <span className="btn-text"> Login</span>
                </button>
              </Link>
              <Link to="/register" className="custom-link">
                <button className="custom-btn btn btn-sm btn-outline-light">
                  <i className="fa-solid fa-user"></i>
                  <span className="btn-text"> Register</span>
                </button>
              </Link>
            </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
