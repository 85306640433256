import React from "react";
import "./HomePage.css"

import consulting2 from "../images/consulting2.jpg";
import seminar from "../images/seminar.jpg";
import demo from "../images/demo.avif";
import training from "../images/training.jpg";
import staffing2 from "../images/staffing2.jpg";

const ServicesPage=()=>{
    return(
        <>
            <div className='ps-3 pe-3 pmg-fontfamily'>
            <section id="browse" className="mt-4">
            <div>
                <h3 className="hed ">Our Services</h3>
            </div>
            <div className="container-fluid mt-3 bg-light" >
                <ul className="nav nav-tabs justify-content-center" id="myTabs" role="tablist">
                <li className="nav-item" role="presentation">
                    <a className="nav-link active" id="home-tab" data-bs-toggle="tab" href="#design" role="tab" aria-controls="home" aria-selected="true">Consulting</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" id="profile-tab" data-bs-toggle="tab" href="#marketing" role="tab" aria-controls="profile" aria-selected="false">Seminar</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" id="contact-tab" data-bs-toggle="tab" href="#finance" role="tab" aria-controls="contact" aria-selected="false">Demo</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" id="music-tab" data-bs-toggle="tab" href="#music" role="tab" aria-controls="music" aria-selected="false">Training</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" id="staffing-tab" data-bs-toggle="tab" href="#education" role="tab" aria-controls="staffing" aria-selected="false">Staffing</a>
                </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="design" role="tabpanel" aria-labelledby="home-tab">
                    <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-4 ">
                        
                        <div className="card shadow p-3 zoomimg">
                            <div className="card-body">
                            <h4 className="card-title ">Consulting</h4>
                            <h6 className="card-text ">Consulting services to transform your business</h6>
                            <p className="card-text ">Using the latest technologies and deep industry insights, we help businesses of every size prosper by creating impactful solutions tailored to your unique needs today and for the future.</p>
                            </div>
                            <img src={consulting2} className="card-img-bottom imgh " height="300px" alt="consulting2"/>
                        </div>
                        </div>
                        <div className="col-md-8">
                        <h3 className="text-center">Consulting</h3>
                        <p className="mt-2 m-4">Consultancy firms are hired to help their clients with a dilemma, for which specialist expertise or knowledge is needed which is otherwise lacking on the clients’ end. Another feature is that consultancy firms form an independent party, which can give the clients an objective perspective on the problems that their organisation struggles with, and can offer them solutions toward the problem. Involving a consultancy firm can also, in some cases, be more cost effective than if an organisation were to hire experts themselves. Furthermore, an organisation often simply does not have sufficient capacity to manage its change portfolio, which can be done by hiring an external consultancy firm instead.</p>
                        <p className="mt-2 m-4">Consultants, similarly, are business experts. They can lead the client through a fact-based analysis of their business problem and the evaluation of alternative courses of action. They can also leverage their firm’s collective knowledge to bring extensive industry and functional expertise to bear to solve the problem. </p>
                        <ul className="mt-2 m-4">
                            <li>Strategy Consultant</li>
                            <li>Management Consultant</li>
                            <li>Operations Consultant</li>
                            <li>Financial Advisory Consultant</li>
                            <li>Human Resource Consultant</li>
                            <li>IT Consultant</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    </div>
                <div className="tab-pane fade" id="marketing" role="tabpanel" aria-labelledby="profile-tab">
                    <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-4 ">
                        
                        <div className="card shadow p-3 zoomimg">
                            <div className="card-body">
                            <h5 className="card-title ">Seminar</h5>
                            <p className="card-text fs-6">A seminar is a form of academic instruction, either at an academic institution or offered by a commercial or professional organization. </p>
                            
                            </div>
                            <img src={seminar} className="card-img-bottom imgh " height="300px" alt="seminar"/>
                        </div>
                        </div>
                        <div className="col-md-8">
                        <h5 className="text-center">Seminar</h5>
                        <p className="mt-2 m-4">A seminar course is an event, either virtual or in person, hosted by a corporate company or small business owners that focuses primarily on educating customers on either new products, recurring problems or just sharing knowledge that the company has.</p>
                        <p className="mt-2 m-4">The organizer must ensure that the guest is welcomed and know exactly where to be seated. An introduction and some background on the company, the cause, or other information for the seminar is always important. After all this, the guest speakers can share the knowledge that all the customers came for.</p>
                        <p className="mt-2 m-4">The meeting will have breaks to ensure the information is collected and the clients can focus consistently. After the entire session, there must be an open time for questions and debates to ensure that every individual understands the importance of all the information shared. </p>
                        <ul>
                            <li>Academic Seminars</li>
                            <li>Professional Seminars</li>
                            <li>Public Seminars</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="finance" role="tabpanel" aria-labelledby="contact-tab">
                    <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-4 ">
                        
                        <div className="card shadow p-3 zoomimg">
                            <div className="card-body">
                            <h3 className="card-title ">Demo</h3>
                            <p className="card-text fs-6">Demos are usually spread among users who might be interested in trying the product, for them to test it before buying, and also with the advertising purpose. Besides, a demo serves as the presentation and is an integral part of the product marketing process.</p>
                            
                            </div>
                            <img src={demo} className="card-img-bottom imgh " height="300px" alt="demo"/>
                        </div>
                        </div>
                        <div className="col-md-8">
                        <h5 className="text-center">Demo</h5>
                        <p className="mt-2 m-4">A demo is what you give to show how something works. You might give a demo of your fancy new espresso machine to your weekend guests, so they'll know how to use it. Demo is short for demonstrate or demonstration. It can be a verb, as when a tech company demos its new tablet or laptop.</p>
                        <p className="mt-2 m-4">In marketing, a product demonstration (or "demo" for short) is a promotion where a product is demonstrated to potential customers.The goal is to introduce customers to the product in hopes of getting them to purchase that item.</p>
                        <p className="mt-2 m-4">Products offered as samples during these demonstrations may include new products, new versions of existing products or products that have been recently introduced to a new commercial marketplace.

                            Product demonstration enhances the quality of the sales presentation by providing a visual support. It is provided to be effective way to address the prospect 's specific product-related concerns</p>
                            <p className="mt-2 m-4">that software was pretty interesting." In the music industry, a demo is a practice version of a song, meant to show a record label or music venue what a performer's music sounds like. This meaning's been around since the 1960s, while earlier it meant "a political demonstration.</p>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="music" role="tabpanel" aria-labelledby="trainning-tab">
                    <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-4 ">
                        
                        <div className="card shadow p-3 zoomimg">
                            <div className="card-body">
                            <h5 className="card-title ">Training</h5>
                            <p className="card-text fs-6">Successful businesses understand that its more beneficial and cost-effective to develop their existing employees instead of seeking out new talent.  </p>
                            
                            </div>
                            <img src={training} className="card-img-bottom imgh " height="300px" alt="training"/>
                        </div>
                        </div>
                        <div className="col-md-8">
                        <h5 className="text-center">Training</h5>
                        <p className="mt-2 m-4">Training and development initiatives are educational activities within an organization that are designed to improve the job performance of an individual or group. These programs typically involve advancing a worker’s knowledge and skill sets and instilling greater motivation to enhance job performance.</p>
                        <p className="mt-2 m-4">Training is the action of informing or instructing your employees on a certain task in order to help them improve their performance or knowledge.  If people are to perform their job to the highest possible standard, they must be effectively and efficiently trained.</p>
                        <p className="mt-2 m-4">Effective training will mean the activities have achieved the specific outcomes required. In addition, your workers need to gain or maintain the skills and knowledge they need to perform their work, direct others to perform work and to supervise work. Lack of training can be attributed to one of the reasons of real quality problems.</p>
                        <ul>
                            <li>Orientation Training</li>
                            <li>Product Training</li>
                            <li>Technical Training</li>
                            <li>Team Training</li>
                            <li>Quality Assurance (Q/A) Training</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="education" role="tabpanel" aria-labelledby="staffing-tab">
                    <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-4 ">
                        
                        <div className="card shadow p-3 zoomimg">
                            <div className="card-body">
                            <h5 className="card-title ">Staffing</h5>
                            <p className="card-text fs-6">Staffing is the process of hiring eligible candidates in the organization or company for specific positions.</p>
                            
                            </div>
                            <img src={staffing2} className="card-img-bottom imgh " height="300px" alt="staffing2"/>
                        </div>
                        </div>
                        <div className="col-md-8">
                        <h5 className="text-center">Staffing</h5>
                        <p className="mt-2 m-4">Staffing is crucial to ensuring that your business can operate at maximum productivity and meet the needs of your customers.</p>
                        <p className="mt-2 m-4">Good staffing means that you have the right employees in the right roles. It also means that these roles are filled during all the hours they’re needed. Employees can then focus on the work they do best and trust that everyone else in your organization is doing the same. In other words, your business can operate as it’s intended to.</p>
                        <p className="mt-2 m-4">As employees turn over, good staffing also provides a pipeline for replacing them. This ensures that even though employees don’t stay with your company forever, your business can keep functioning smoothly.</p>
                        <p className="mt-2 m-4">Poor staffing, on the other hand, can leave your business lurching from crisis to crisis. You may not have all roles filled, leaving employees to pick up extra tasks. This can lead to employee burnout and result in an even more dire labor situation.</p>
                            <ul>
                            <li> Hire staffing</li>
                            <li>Short-term staffing</li>
                            <li>Direct hire staffing</li>
                            <li>Payroll services staffing</li>
                            <li> Strategic staffing</li>
                            </ul>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>


            </section>
            </div>        
        </>
    )
}

export default ServicesPage