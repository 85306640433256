import React from "react";
import TeamMember from "./TeamMember ";


const Team = () => {

  const teamMembers = [
      {
        name: "Sujatha Tadiboyna",
        role: "Chair & M.D",
        imageUrl: "https://pinkmoontech.com/images/team-11-420x424.jpg",
        linkedinurl:"",
        gmailurl:""
      },
      {
        name: "Nagamalleswararao",
        role: "Chief Executive Officer",
        imageUrl: "https://pinkmoontech.com/images/team-14-420x424.jpg",
        linkedinurl:"",
        gmailurl:""
      },
      {
        name: "Vanukuru Sai Karthik",
        role: "Web Developer",
        imageUrl: "https://pinkmoontech.com/images/team-14-420x424.jpg",
        linkedinurl:"https://www.linkedin.com/in/vanukuru-siva-sai-karthik-a1680b290",
        gmailurl:"vanukurusivasai@gmail.com"
      },
      {
        name: "Harish Ginjupalli",
        role: "Full Stack Developer",
        imageUrl: "https://pinkmoontech.com/images/team-14-420x424.jpg",
        linkedinurl:"https://www.linkedin.com/in/harish-ginjupalli-0445ab181",
        gmailurl:"harishginjupalli98@gmail.com"
      },
      {
        name: "Charan",
        role: "Software Developer",
        imageUrl: "https://pinkmoontech.com/images/team-14-420x424.jpg",
        linkedinurl:"",
        gmailurl:"charankumarreddy03@gmail.com"
      },

      // Add more team members here
      
    ];

  return (
    <div className="container">
        <br />
        <h3 className="main-header wow bounceIn animated" data-wow-duration="1s" data-wow-offset="70" align="center" style={{ color: "#6fa337" }}>Meet The Team</h3>
        <p align="center">Our executive leadership team brings a wealth of knowledge and expertise to the IT industry.</p>
        <div className="row mt-5">
        {teamMembers.map((member, index) => (
            <TeamMember key={index} {...member} />
        ))}
        </div>
    </div>
  );
};

export default Team;
