import React, { useState, useEffect } from 'react';
import './Testimonial.css';
import axios from 'axios';

const Testimonial = () => {
  const [data, setData] = useState([]);
  // console.log(data[0].image)
  async function fetchData() {
    try {
      const response = await axios.get('http://localhost:5400/testimonials');
      setData(response.data);

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []);

  return (
    <div className="container-testimonial">
      <div>
      <h2 className='text-center testimonials'>Testimonials</h2>
        <ul className="cards1">
          {data.map((testimonial, index) => (
            <li className="card" key={index}>
              <div>
              <div className="tm d-flex justify-content-center">
              <img src={testimonial.image} alt={`testimonial${index + 1}`} className="rounded-circle" />
            </div>
            <p className="fw-semibold text-center regblocks mt-2">{testimonial.name}</p>
            <p className="text-center rating12">
                {generateStars(testimonial.rating)}
              </p>
                <div className="card-content">
                  <p>{testimonial.description}</p>
                </div>
              </div>
             
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
// Function to generate star icons
const generateStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<i key={i} className="fa-regular fa-star" />);
    }
    return stars;
  };
export default Testimonial;