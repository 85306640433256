import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';

const EventListDetails = () => {
  const { eventid } = useParams(); // Extract eventId from the URL
  const [event, setEvent] = useState(null);

  useEffect(() => {
    // Make an API request to fetch the event details using the eventId
    axios
      .get(`http://localhost:5400/events/${eventid}`)
      .then((res) => {
        setEvent(res.data);
        console.log(res)
      })
      .catch((err) => {
        console.error('Error fetching event details:', err);
      });
  }, [eventid]);

  // Function to format the date to exclude hours and seconds
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className='container'>
      {event ? (
        <div>
          <h1>{event.eventdetails}</h1>
          <p>Date: {formatDate(event.eventdate)}</p>
          {event.eventimage && (
            <div>
              <img src={event.eventimage} className='image' alt="Event" />
            </div>
          )}
          <p>Description: {event.description}</p>
          <Link to={`/event-registration/${eventid}`} className='btn btn-success'>
            Click Here to Register
          </Link>
        </div>
      ) : (
        <p>Loading event details...</p>
      )}
    </div>
  );
};

export default EventListDetails;