import React, { useState, useEffect } from 'react';
import './EventList.css';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

const EventList = () => {
  // Initialize current date state with the current date
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [events, setEvents] = useState([]);

  // Update current date to the actual current date and time every minute
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000); // Update every minute (adjust the interval as needed)

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    axios.get('http://localhost:5400/events-data') // Adjust the API endpoint
      .then((response) => {
        setEvents(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error('Error fetching events:', error);
      });
  }, []);

  // Sort events based on event date
  events.sort((a, b) => new Date(a.eventdate) - new Date(b.eventdate));

  // Function to format the date to display only the date part
  const formatDate = (dateString) => {
    const eventDate = new Date(dateString);
    return eventDate.toLocaleDateString(); // Format the date
  };

  // Separate events into upcoming, current day, and past events
  const upcomingEvents = events.filter((event) => {
    const eventDate = new Date(event.eventdate);
    return eventDate > currentDateTime;
  });

  const currentDayEvents = events.filter((event) => {
    const eventDate = new Date(event.eventdate);
    return (
      eventDate >= new Date(currentDateTime.setHours(0, 0, 0, 0)) &&
      eventDate <= new Date(currentDateTime.setHours(23, 59, 59, 999))
    );
  });



  const navigate = useNavigate(); // Initialize the navigate function

  const handleViewDetails = (eventid) => {
    console.log(eventid);
    // Use the navigate function to redirect to the EventDetails route
    navigate(`/events/${eventid}`);
  };

  return (
    <div className="container pmg-fontfamily">
      <h1 className="text-center">Events</h1>
      <Link to="/event-create">Register here to display your events</Link>
      <h2 className="text-center">Upcoming Events</h2>
      <div className="row">
        {upcomingEvents.map((event) => (
          <div className="col-sm-2 custom-card" key={event.eventid}>
            <div className="card mb-4">
              <div className="card-body">
                <h2 className="card-title">{event.eventdetails}</h2>
                <p>Date: {formatDate(event.eventdate)}</p> {/* Display formatted date */}
                <p>Time: {event.eventtime}</p>
                <p className="card-text">{event.description}</p>
              </div>
            </div>
            <button
              className='btn btn-primary w-100 rounded-0'
              onClick={() => handleViewDetails(event.eventid)}
            >
              View Details
            </button>
          </div>
        ))}
      </div>
      <h2 className="text-center">Current Day Events</h2>
      <div className="row">
        {currentDayEvents.map((event) => (
          <div className="col-sm-2 custom-card" key={event.eventid}>
            <div className="card mb-4">
              <div className="card-body">
                <h2 className="card-title">{event.eventdetails}</h2>
                <p>Date: {formatDate(event.eventdate)}</p> {/* Display formatted date */}
                <p>Time: {event.eventtime}</p>
                <p className="card-text">{event.description}</p>
                <button
                  className='btn btn-primary w-100 rounded-0'
                  onClick={() => handleViewDetails(event.eventid)}
                >
                  View Details
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>


      
    </div>
  );
};

export default EventList;