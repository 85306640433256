import React from 'react';
import { FaFacebookF,FaYoutube, FaTwitter, FaInstagram, FaLinkedin,  FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';


export default function NavbarFooter() {
  return (
    <>
      <div className="container-fluid bg-secondary pmg-fontfamily" style={{ fontFamily: 'Roboto, sans-serif', backgroundColor: '#f8f9fa', paddingTop: '20px', paddingBottom: '20px' }}>
        <div className="row text-white" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <div className="col-md-6 text-white">
            <h4 className='text-center text-white pmg-fontfamily'>About company</h4>
            
            <div className="text-left ">
              <p className="text-white pmg-fontfamily">At PinkMoon Technologies, we provide business transformation powered by sound IT strategy, consulting, cutting-edge technology solutions, data warehouse & business intelligence, and innovative quality assurance techniques.</p>
              <p className="text-white pmg-fontfamily">Our customer-centric approach combined with domain expertise in Retail, Insurance, Healthcare, Education, and Research help us nurture a strong partnership with our customers across the globe.</p>
            </div>
          </div>
          <div className="col-md-6">
            <h4 className='text-center text-white pmg-fontfamily'>Address</h4>
          
            <div className="text-left">
              <div className="row">
                <div className="col-lg-1"> <FaMapMarkerAlt /></div>
                <div className="col-lg-11" ><p className='text-white pmg-fontfamily'>11-182, First Floor, Sreemannarayana Street, Ashok Nagar,<br></br> Vijayawada, Andhra Pradesh-520010</p></div>
              </div>
              <div className="row">
                <div className="col-lg-1"> <FaEnvelope /></div>
                <p className="col-lg-11 text-white pmg-fontfamily">info@pinkmoontech.com</p>
              </div>
              <div className="row">
                <div className="col-lg-1"> <FaPhone /></div>
                <p className="col-lg-11 text-white pmg-fontfamily">+91 83283 47959</p>
              </div>
              <div className='text-center'>
                <a href="https://www.facebook.com/" style={{ marginRight: '30px' }}><FaFacebookF style={{ color: 'blue' }} size={28} /></a>
                <a href="https://twitter.com/i/flow/login" style={{ marginRight: '30px' }}><FaTwitter style={{ color: '00AAEE' }} size={28} /></a>
                <a href="https://www.instagram.com/" style={{ marginRight: '30px' }}><FaInstagram style={{ background: 'linear-gradient(to bottom,#feda75,#fa7e1e, #d62976)' }} size={28} /></a>
                {/* <a href="https://www.linkedin.com/company/login" style={{ marginRight: '30px' }}><FaLinkedin style={{ color: '0072B1' }} size={28} /></a> */}
                <a href="https://www.youtube.com/" style={{ marginRight: '30px' }}><FaYoutube style={{ color: '#FF0000' }} size={28} /></a>

              </div>
            </div>
          </div>
         
           
            
          
        </div>
        {/* <div className="container-fluid"> */}
          <div className="row" >
            <div className="col-12 text-center bg-danger mt-2" style={{ paddingTop: '10px', paddingBottom: '1px' }}>
              <p className='pmg-fontfamily'>&copy; 2023 PINKGEO Technologies Private Limited. All rights reserved.</p>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
}
