// // import React, { useEffect, useState } from 'react';
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
// // import "./LoginPage2.css"
// // import { Link } from 'react-router-dom';

// // const LoginPage2 = () => {
// //   const [isLoggedIn, setIsLoggedIn] = useState(false);
// //   const [contactNumber, setContactNumber] = useState('');
// //   const [password, setPassword] = useState('');
// //   const [loginMessage, setLoginMessage] = useState('');
// //   const navigate = useNavigate();
// //   const [userType, setUserType] = useState('');
// //   const [userId, setUserId] = useState('');

// //   useEffect(() => {
// //       // Check if the user is already logged in based on the flag status
// //       const checkLoginStatus = async () => {
// //           try {
// //               const response = await axios.get('http://localhost:5400/check-flag');
// //               const data = response.data;

// //               setIsLoggedIn(data.loggedIn);
// //               setUserType(data.userType);
// //               setUserId(data.userId);
// //           } catch (error) {
// //               console.error('Error checking login status:', error);
// //           }
// //       };

// //       checkLoginStatus();
// //   }, []);

// //   const handleLogin = async () => {
// //       try {
// //           const response = await axios.post('http://localhost:5400/login', {
// //               mobileNumber: contactNumber,
// //               password: password,
// //           });

// //           const data = response.data;

// //           if (data.success) {
// //               setIsLoggedIn(true);
// //               setLoginMessage(`Logged in as ${data.userType}`);
// //               setUserType(data.userType);
// //               setUserId(data.userId);
// //               alert('Login successful');
// //               if (data.userType === 'expert') {
// //                   navigate('/expert-profile');
// //               } else if (data.userType === 'organization') {
// //                   navigate('/org-profile');
// //               }

// //               // Update the flag to 1 for the logged-in user in the database
// //               await axios.post('http://localhost:5400/update-flag', {
// //                   userType: data.userType,
// //                   userId: data.userId,
// //                   flag: 1,
// //               });
// //           } else {
// //               setLoginMessage('Invalid credentials');
// //           }
// //       } catch (error) {
// //           console.error('Login error:', error);
// //           setLoginMessage('An error occurred while logging in');
// //       }
// //   };

// //   const handleLogout = async () => {
// //       try {
// //           await axios.post('http://localhost:5400/logout');
// //           setIsLoggedIn(false);
// //           setLoginMessage('Logout successful');

// //           // Update the flag to 0 for the logged-out user in the database
// //           await axios.post('http://localhost:5400/update-flag', {
// //               userType,
// //               userId,
// //               flag: 0,
// //           });
// //       } catch (error) {
// //           console.error('Logout error:', error);
// //       }
// //   };
  
// //   return (
// //     <div className='pmg-fontfamily' style={{ borderColor:"red",borderWidth:"2px",display:'relative',marginTop:'38px', minHeight: '30vh', paddingTop: '40px', paddingBottom: '40px',paddingLeft:'35px'}}>  
// //       <div className="row">
// //         <div className="text-center">
// //           <h1 style={{ color: 'red', fontSize: '1.5rem', fontWeight: 'bold' }}>Login</h1>

// //           <div className="row">
// //             <div className="input-group mb-3">
// //               <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faEnvelope} /></span>
// //               <input type="text" className="form-control" placeholder="Email address/Mobile Number" />
// //             </div>
// //             <br />
// //             <div className="input-group mb-3">
// //               <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faKey} className="password-icon" /></span>
// //               <input type="text" className="form-control" placeholder="Password" />
// //             </div>
// //           </div>

// //           <div className="d-grid gap-2">
// //             <button className="btn btn-danger" type="button">Sign In</button>
// //           </div>

// //         </div>
// //         <div className="row mt-2">
// //           <div className="col-lg-6">
// //             <div className="form-check">
// //               <input type="checkbox" className="form-check-input" id="rememberMe" />
// //               <label className="form-check-label" htmlFor="rememberMe">
// //                 Remember Me
// //               </label>
// //             </div>
// //           </div>
// //           <div className="col-lg-6" style={{ textAlign: 'right' }}>
// //             <a href="" className="forgot-password" >
// //               <Link to="/Forgotpassword">
// //               <p>Forgot Password?</p>
// //               </Link>
// //             </a>
// //           </div>
// //           <div>
// //               <h6 className='text-danger text-center'>*Forgot your username, please contact our support team</h6>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default LoginPage2;





// // // import React, { useState, useEffect } from 'react';
// // // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // // import { faKey, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
// // // import { Link, useNavigate } from 'react-router-dom';
// // // import axios from 'axios';
// // // import ProfileCardList2 from './ProfileCardList2'; // Replace with the correct path to ProfileCardList2 component
// // // import ProfileCardList from './ProfileCardList'; // Replace with the correct path to ProfileCardList component
// // // const LoginPage2 = () => {
// // //   const [contactNumber, setContactNumber] = useState('');
// // //   const [password, setPassword] = useState('');
// // //   const [loginMessage, setLoginMessage] = useState('');
// // //   // const [loginRole, setLoginRole] = useState('');
// // //   const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
// // //   const navigate = useNavigate();

// // //   // Replace with the actual backend URL
// // //   const backendURL = 'http://localhost:5400';

// // //   // useEffect(() => {
// // //   //   // Check if the user is already logged in
// // //   //   const checkLoginStatus = async () => {
// // //   //     try {
// // //   //       const response = await axios.get(`${backendURL}/check-login`);
// // //   //       const data = response.data;

// // //   //       if (data.isLoggedIn) {
// // //   //         // setLoginRole(data.userType);
// // //   //         setIsLoggedIn(true);
// // //   //       }
// // //   //     } catch (error) {
// // //   //       console.error('Error checking login status:', error);
// // //   //     }
// // //   //   };

// // //   //   checkLoginStatus();
// // //   // }, []);

// // //   // const handleLogin = async () => {
// // //   //   try {
// // //   //       const response = await axios.post(`${backendURL}/login`, {
// // //   //       mobileNumber: contactNumber,
// // //   //       password: password,
// // //   //     });

// // //   //     const data = response.data;

// // //   //     if (data.message.includes('successful')) {
// // //   //       if (data.message.includes('expert')) {
// // //   //         // setLoginRole('expert');
// // //   //         setIsLoggedIn(true);
// // //   //         alert("Expert login successful")
// // //   //         navigate('/expert-profile');
// // //   //       } else if (data.message.includes('organization')) {
// // //   //         // setLoginRole('organization');
// // //   //         setIsLoggedIn(true);
// // //   //         navigate('/org-profile');
// // //   //           }
// // //   //     } else {
// // //   //       setLoginMessage('Invalid credentials');
// // //   //     }
// // //   //   } catch (error) {
// // //   //     // console.error('Login error:', error);
// // //   //     setLoginMessage('An error occurred while logging in');
// // //   //   }
// // //   // };

// // //   // const handleLogout = async () => {
// // //   //   try {
// // //   //     await axios.post(`${backendURL}/logout`);

// // //   //     // Clear user data and update login status
// // //   //     // setLoginRole('');
// // //   //     setIsLoggedIn(false);
// // //   //     navigate('/login');
// // //   //   } catch (error) {
// // //   //     console.error('Logout error:', error);
// // //   //   }
// // //   // };


// // //   // checking code
// // //   useEffect(() => {
// // //     // Check if the user is already logged in based on the flag status
// // //     const checkLoginStatus = async () => {
// // //       try {
// // //         const response = await axios.get(`${backendURL}/check-flag`);
// // //         const data = response.data;
  
// // //         if (data.flag === 1) {
// // //           // User is logged in (flag is 1)
// // //           setIsLoggedIn(true);
// // //         }
// // //       } catch (error) {
// // //         console.error('Error checking login status:', error);
// // //       }
// // //     };
  
// // //     checkLoginStatus();
// // //   }, []);

  
// // //   const handleLogin = async () => {
// // //     try {
// // //       const response = await axios.post(`${backendURL}/login`, {
// // //         mobileNumber: contactNumber,
// // //         password: password,
// // //       });
  
// // //       const data = response.data;
  
// // //       if (data.message.includes('successful')) {
// // //         if (data.message.includes('expert')) {
// // //           // setLoginRole('expert');
// // //           setIsLoggedIn(true);
  
// // //           // Update the flag status to 1 (logged in) in the database
// // //           await axios.post(`${backendURL}/update-flag`, { flag: 1 });
  
// // //           alert("Expert login successful");
// // //           navigate('/expert-profile');
// // //         } else if (data.message.includes('organization')) {
// // //           // setLoginRole('organization');
// // //           setIsLoggedIn(true);
  
// // //           // Update the flag status to 1 (logged in) in the database
// // //           await axios.post(`${backendURL}/update-flag`, { flag: 1 });
  
// // //           navigate('/org-profile');
// // //         }
// // //       } else {
// // //         setLoginMessage('Invalid credentials');
// // //       }
// // //     } catch (error) {
// // //       // console.error('Login error:', error);
// // //       setLoginMessage('An error occurred while logging in');
// // //     }
// // //   };
  
// // //   const handleLogout = async () => {
// // //     try {
// // //       await axios.post(`${backendURL}/logout`);
  
// // //       // Clear user data and update login status
// // //       // setLoginRole('');
// // //       setIsLoggedIn(false);
  
// // //       // Update the flag status to 0 (logged out) in the database
// // //       await axios.post(`${backendURL}/update-flag`, { flag: 0 });
  
// // //       navigate('/login');
// // //     } catch (error) {
// // //       console.error('Logout error:', error);
// // //     }
// // //   };
  

// // //   return (
// // //     <div className='pmg-fontfamily' style={{ borderColor: 'red', borderWidth: '2px', display: 'relative', marginTop: '38px', minHeight: '30vh', paddingTop: '40px', paddingBottom: '40px', paddingLeft: '35px' }}>
// // //       <div className="row">
// // //         {isLoggedIn ? (
// // //           // If logged in, display logout button
// // //           <div className="text-center">
// // //             <h3 style={{ color: 'red', fontSize: '1.5rem', fontWeight: 'bold' }}>Welcome,</h3> 
// // //             {/* {loginRole} */}
// // //             <button className="btn btn-sm px-3 me-2 btn-danger " onClick={handleLogout} type="button">Logout</button>
// // //           </div>
// // //         ) : (
// // //           // If not logged in, display login form
// // //           <div className="text-center">
// // //             <h3 style={{ color: 'red', fontSize: '1.5rem', fontWeight: 'bold' }}>Login</h3>
// // //             <div className="row">
// // //               <div className="input-group mb-3">
// // //                 <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faMobileAlt} /></span>
// // //                 <input type="text" className="form-control" placeholder="Mobile Number" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
// // //               </div>
// // //               <br />
// // //               <div className="input-group mb-3">
// // //                 <span className="input-group-text" id="basic-addon1">
// // //                   <FontAwesomeIcon icon={faKey} className="password-icon" />
// // //                 </span>
// // //                 <input type="password" className="form-control" placeholder="Pin" value={password} onChange={(e) => setPassword(e.target.value)} />
// // //               </div>
// // //             </div>
// // //             <div className="d-grid gap-2">
// // //               <button className="btn btn-sm px-3 me-2 btn-danger " onClick={handleLogin} type="button">Sign In</button>
// // //             </div>
// // //             <div className="row mt-2">
// // //               <div className="col-lg-6">
// // //                 <div className="form-check">
// // //                   <input type="checkbox" className="form-check-input" id="rememberMe" />
// // //                   <label className="form-check-label" htmlFor="rememberMe">
// // //                     Remember Me
// // //                   </label>
// // //                 </div>
// // //               </div>
// // //               <div className="col-lg-6" style={{ textAlign: 'right' }}>
// // //                 <Link to="/Forgotpassword">Forgot Password?</Link>
// // //               </div>
// // //             </div>
// // //             <div className='mt-3'>
// // //               <h6 className='text-danger text-center'>*Forgot your username, please contact our support team</h6>
// // //             </div>
// // //             <p>{loginMessage}</p>

// // //             {/* Conditional rendering of profile card based on login role */}
// // //             {/* <div className="row mt-2">
// // //               {loginRole === 'expert' && (
// // //                 <Link to="/expert-profile"> */}
// // //                  {/* Render the expert profile card here */}
// // //                   {/* <ProfileCardList2 /> */}
// // //                 {/* </Link>
// // //               )}
// // //               {(loginRole === 'aa')?(<Link to="/org-profile"> */}

// // //                    {/* Render the organization profile card here */}
// // //                   {/* <ProfileCardList /> */}
// // //                 {/* </Link>):(<p>message</p>)
// // //               }
// // //             </div>  */}
// // //           </div>
// // //         )}
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default LoginPage2;



// // import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// // import { useNavigate } from 'react-router-dom';

// // function LoginPage() {
// //     const [isLoggedIn, setIsLoggedIn] = useState(false);
// //     const [contactNumber, setContactNumber] = useState('');
// //     const [password, setPassword] = useState('');
// //     const [loginMessage, setLoginMessage] = useState('');
// //     const navigate = useNavigate();
// //     const [userType, setUserType] = useState('');
// //     const [userId, setUserId] = useState('');

// //     useEffect(() => {
// //         // Check if the user is already logged in based on the flag status
// //         const checkLoginStatus = async () => {
// //             try {
// //                 const response = await axios.get('http://localhost:5400/check-flag');
// //                 const data = response.data;

// //                 setIsLoggedIn(data.loggedIn);
// //                 setUserType(data.userType);
// //                 setUserId(data.userId);
// //             } catch (error) {
// //                 console.error('Error checking login status:', error);
// //             }
// //         };

// //         checkLoginStatus();
// //     }, []);

// //     const handleLogin = async () => {
// //         try {
// //             const response = await axios.post('http://localhost:5400/login', {
// //                 mobileNumber: contactNumber,
// //                 password: password,
// //             });

// //             const data = response.data;

// //             if (data.success) {
// //                 setIsLoggedIn(true);
// //                 setLoginMessage(`Logged in as ${data.userType}`);
// //                 setUserType(data.userType);
// //                 setUserId(data.userId);
// //                 alert(`Login successful ${data.userType}`);
// //                 if (data.userType === 'expert') {
// //                     navigate('/expert-profile');
// //                 } else if (data.userType === 'organization') {
// //                     navigate('/org-profile');
// //                 }

// //                 // Update the flag to 1 for the logged-in user in the database
// //                 await axios.post('http://localhost:5400/update-flag', {
// //                     userType: data.userType,
// //                     userId: data.userId,
// //                     flag: 1,
// //                 });
// //             } else {
// //                 setLoginMessage('Invalid credentials');
// //             }
// //         } catch (error) {
// //             console.error('Login error:', error);
// //             setLoginMessage('An error occurred while logging in');
// //         }
// //     };

// //     const handleLogout = async () => {
// //         try {
// //             await axios.post('http://localhost:5400/logout');
// //             setIsLoggedIn(false);
// //             setLoginMessage('Logout successful');

// //             // Update the flag to 0 for the logged-out user in the database
// //             await axios.post('http://localhost:5400/update-flag', {
// //                 userType,
// //                 userId,
// //                 flag: 0,
// //             });
// //         } catch (error) {
// //             console.error('Logout error:', error);
// //         }
// //     };

// //     return (
// //          <div className='pmg-fontfamily' style={{ borderColor: 'red', borderWidth: '2px', display: 'relative', marginTop: '38px', minHeight: '30vh', paddingTop: '40px', paddingBottom: '40px', paddingLeft: '35px' }}>
// //             {isLoggedIn ? (
// //                 <div>
// //                     <p>{loginMessage}</p>
// //                     <button onClick={handleLogout}>Logout</button>
// //                 </div>
// //             ) : (
// //                 <div>
// //                     <label htmlFor="contactNumber">Mobile Number:</label>
// //                     <input type="text" id="contactNumber" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} required /><br /><br />
// //                     <label htmlFor="password">Password:</label>
// //                     <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required /><br /><br />
// //                     <button onClick={handleLogin}>Login</button>
// //                     <p>{loginMessage}</p>
// //                 </div>
// //             )}
// //         </div>
// //     );
// // }

// // export default LoginPage;

// import React, { useState, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
// import axios from 'axios';
// import { Link, useNavigate } from 'react-router-dom';
// import "./LoginPage2.css";
// function LoginPage() {
//     const [isLoggedIn, setIsLoggedIn] = useState(false);
//     const [contactNumber, setContactNumber] = useState('');
//     const [password, setPassword] = useState('');
//     const [loginMessage, setLoginMessage] = useState('');
//     const navigate = useNavigate();
//     const [userType, setUserType] = useState('');
//     const [userId, setUserId] = useState('');

//     useEffect(() => {
//         // Check if the user is already logged in based on the flag status
//         const checkLoginStatus = async () => {
//             try {
//                 const response = await axios.get('http://localhost:5400/check-flag');
//                 const data = response.data;

//                 setIsLoggedIn(data.loggedIn);
//                 setUserType(data.userType);
//                 setUserId(data.userId);
//             } catch (error) {
//                 console.error('Error checking login status:', error);
//             }
//         };

//         checkLoginStatus();
//     }, []);

//     const handleLogin = async () => {
//         try {
//             const response = await axios.post('http://localhost:5400/login', {
//                 mobileNumber: contactNumber,
//                 password: password,
//             });

//             const data = response.data;

//             if (data.success) {
//                 setIsLoggedIn(true);
//                 setLoginMessage(`Logged in as ${data.userType}`);
//                 setUserType(data.userType);
//                 setUserId(data.userId);
//                 alert(`Login successful ${data.userType}`);
//                 if (data.userType === 'expert') {
//                     navigate('/UserNavbar2');
//                     alert()
//                 } else if (data.userType === 'organization') {
//                     navigate('/UserNavbar');
//                 }

//                 // Update the flag to 1 for the logged-in user in the database
//                 await axios.post('http://localhost:5400/update-flag', {
//                     userType: data.userType,
//                     userId: data.userId,
//                     flag: 1,
//                 });
//             } else {
//                 setLoginMessage('Invalid credentials');
//             }
//         } catch (error) {
//             console.error('Login error:', error);
//             setLoginMessage('An error occurred while logging in');
//         }
//     };

//     const handleLogout = async () => {
//         try {
//             await axios.post('http://localhost:5400/logout');
//             setIsLoggedIn(false);
//             setLoginMessage('Logout successful');

//             // Update the flag to 0 for the logged-out user in the database
//             await axios.post('http://localhost:5400/update-flag', {
//                 userType,
//                 userId,
//                 flag: 0,
//             });
//         } catch (error) {
//             console.error('Logout error:', error);
//         }
//     };

//     return (
//         <div className='pmg-fontfamily mb-4'>
//             {isLoggedIn ? (
//                 <div>
//                     <p>{loginMessage}</p>
//                     <button onClick={handleLogout}>Logout</button>
//                 </div>
//             ) : (<div className='pmg-fontfamily' style={{ borderColor:"red",borderWidth:"2px",display:'relative',marginTop:'38px', minHeight: '30vh', paddingTop: '40px', paddingBottom: '40px',paddingLeft:'35px'}}>  
//                      <div className="row">
//                        <div className="text-center">
//                          <h1 style={{ color: 'red', fontSize: '1.5rem', fontWeight: 'bold' }}>Login</h1>
//                          <div className="row">
//                           <div className="input-group mb-3">
//                             <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faEnvelope} /></span>
//                             <input type="text" placeholder="Mobile Number" id="contactNumber" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} required/><br /><br />
//                           </div>
//                           <br />
//                           <div className="input-group mb-3">
//                             <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faKey} className="password-icon" /></span>
//                             <input type="password" placeholder='Pin' id="password" value={password} onChange={(e) => setPassword(e.target.value)} required /><br /><br />
//                           </div>
//                         </div>

//                         <div className="d-grid gap-2">
//                           <button onClick={handleLogin} className="btn btn-sm btn-danger" type="button">Sign In</button>
//                         </div>
//                         <div className="row mt-2">
//                           <div className="col-lg-6">
//                             <div className="form-check">
//                               <input type="checkbox" className="form-check-input" id="rememberMe" />
//                               <label className="form-check-label" htmlFor="rememberMe">
//                                 Remember Me
//                               </label>
//                             </div>
//                           </div>
//                           <div className="col-lg-6" style={{ textAlign: 'right' }}>
                          
//                               <Link to="/forgotpassword">
//                               <p>Forgot Password?</p>
//                               </Link>
                        
//                           </div>
//                           <div>
//                               <h6 className='text-danger text-center'>*Forgot your username, please contact our support team</h6>
//                           </div>
//                         </div>
//                         <p>{loginMessage}</p>

//                     {/* <label htmlFor="contactNumber">Mobile Number:</label>
//                     <input type="text" id="contactNumber" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} required /><br /><br />
//                     <label htmlFor="password">Password:</label>
//                     <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required /><br /><br />
//                     <button onClick={handleLogin}>Login</button> */}
                    
//                 </div>
//                 </div>
//                 </div>
//             )}
//         </div>
//     );
// }

// export default LoginPage;








import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import "./LoginPage2.css";
function LoginPage2() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [contactNumber, setContactNumber] = useState('');
    const [password, setPassword] = useState('');
    const [loginMessage, setLoginMessage] = useState('');
    const navigate = useNavigate();
    const [userType, setUserType] = useState('');
    const [userId, setUserId] = useState('');

    useEffect(() => {
        // Check if the user is already logged in based on the flag status
        const checkLoginStatus = async () => {
            try {
                const response = await axios.get('http://localhost:5400/check-flag');
                const data = response.data;

                setIsLoggedIn(data.loggedIn);
                setUserType(data.userType);
                setUserId(data.userId);
            } catch (error) {
                console.error('Error checking login status:', error);
            }
        };

        checkLoginStatus();
    }, []);

    const handleLogin = async () => {
        try {
            const response = await axios.post('http://localhost:5400/login', {
                mobileNumber: contactNumber,
                password: password,
            });

            const data = response.data;

            if (data.success) {
                setIsLoggedIn(true);
                setLoginMessage(`Logged in as ${data.userType}`);
                setUserType(data.userType);
                setUserId(data.userId);
                alert(`Login successful ${data.userType}`);
                if (data.userType === 'expert') {
                    navigate('/UserNavbar2');
                    alert()
                } else if (data.userType === 'organization') {
                    navigate('/UserNavbar');
                }

                // Update the flag to 1 for the logged-in user in the database
                await axios.post('http://localhost:5400/update-flag', {
                    userType: data.userType,
                    userId: data.userId,
                    flag: 1,
                });
            } else {
                setLoginMessage('Invalid credentials');
            }
        } catch (error) {
            console.error('Login error:', error);
            setLoginMessage('An error occurred while logging in');
        }
    };

    const handleLogout = async () => {
        try {
            await axios.post('http://localhost:5400/logout');
            setIsLoggedIn(false);
            setLoginMessage('Logout successful');

            // Update the flag to 0 for the logged-out user in the database
            await axios.post('http://localhost:5400/update-flag', {
                userType,
                userId,
                flag: 0,
            });
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <div className='pmg-fontfamily mb-4'>
            {isLoggedIn ? (
                <div>
                    <p>{loginMessage}</p>
                    <button onClick={handleLogout}>Logout</button>
                </div>
            ) : (<div className='pmg-fontfamily' style={{ borderColor:"red",borderWidth:"2px",display:'relative',marginTop:'38px', minHeight: '30vh', paddingTop: '40px', paddingBottom: '40px'}}>  
                     <div className="row">
                       <div className="">
                         <h1 style={{ color: 'red', fontSize: '1.5rem', fontWeight: 'bold'  }} className='text-center'>Login</h1>
                         <div className="row">
                            <div className="input-group mb-3  number text-center">
                                <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faEnvelope} /></span>
                                <input type="text" placeholder="Mobile Number" id="contactNumber" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} required/><br /><br />
                            </div>
                            <br />
                            <div className="input-group mb-3 number  text-center">
                                <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faKey} className="password-icon" /></span>
                                <input type="password" placeholder='Pin' id="password" value={password} onChange={(e) => setPassword(e.target.value)} required /><br /><br />
                            </div>
                         </div>

                        <div className="d-grid  ">
                          <button onClick={handleLogin} className="btn btn-sm btn-danger" type="button">Sign In</button>
                        </div>
                        <div className="row mt-2">
                          <div className="col-lg-6">
                            <div className="form-check">
                              <input type="checkbox" className="form-check-input" id="rememberMe" />
                              <label className="form-check-label" htmlFor="rememberMe">
                                Remember Me
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6" style={{ textAlign: 'right' }}>
                          
                              <Link to="/forgotpassword">
                              <p>Forgot Password?</p>
                              </Link>
                        
                          </div>
                          <div>
                              <h6 className='text-danger text-center'>*Forgot your username, please contact our support team</h6>
                          </div>
                        </div>
                        <p>{loginMessage}</p>

                    {/* <label htmlFor="contactNumber">Mobile Number:</label>
                    <input type="text" id="contactNumber" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} required /><br /><br />
                    <label htmlFor="password">Password:</label>
                    <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required /><br /><br />
                    <button onClick={handleLogin}>Login</button> */}
                    
                </div>
                </div>
                </div>
            )}
        </div>
    );
}

export default LoginPage2;