import React, { useState } from 'react';
import './TwilioSms.css'; // Import your custom CSS file for styling

function Twilio() {
  const [to, setTo] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    // Send SMS using backend code
    fetch('http://localhost:5400/send-sms', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ to, message })
    })
    .then(response => response.json())
    .then(data => {
      console.log('SMS sent:', data);
      alert('SMS sent successfully!');
    })
    .catch(error => {
      console.error('Error sending SMS:', error);
      alert('Failed to send SMS.');
    });
  };

  return (
  <div className="twilio-container pmg-fontfamily mt-4">
      <h1>Send SMS</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="to">Recipient's Phone Number:</label>
        <input
          type="text"
          id="to"
          name="to"
          value={to}
          onChange={(event) => setTo(event.target.value)}
          required
        />
        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          rows="4"
          required
        />
        <div className='mt-2'>
          <button type="submit">Send SMS</button>
        </div>
      </form>
    </div>
  );
}

export default Twilio;
