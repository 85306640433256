import React, { useState } from 'react';
import LoginPage1 from './LoginPage1';
import LoginPage2 from './LoginPage2';

let LoginPage = () => {
  

  return (
   <React.Fragment>
    <div className="pmg-fontfamily">
  <div style={{ minHeight: "35vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
  <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
    <div className="col-lg-6 " >
      <LoginPage1></LoginPage1>
    </div>
    <div className="col-lg-6 ">
      <LoginPage2></LoginPage2>
    </div>
  </div>
</div>

</div>
   </React.Fragment>
  );
};

export default LoginPage;
