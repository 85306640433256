
import React from 'react';
import "./ProfileCard.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

function ProfileCard2({ name, Organization, experience, location, imagesrc, id }) {
  
console.log(imagesrc);
  return (
    <div className="pmg-fontfamily col-sm-6 col-lg-6  pe-0 width">
       {/* col-sm-6 col-lg-6  */}
      <div className="cards m-b-30 card-background-color">
        <div className="media">
          <div className="mt-0 d-inline-block">
            <div className="mt-0">

              <img className="img-fluid rounded thumb-lg ms-0 me-0" src={imagesrc}  alt="" />
              {/* src="https://montgomeryplanning.org/blog-design/wp-content/uploads/2017/10/Office_Floor_Columns.jpg" */}
            </div>
            <div className="d-inline expert-name d-flex mt-3 text-wrap"  style={{width:"6rem"}}>
              <h5 className="mt-0 font-18 mb-1 d-flex">{name}</h5 >
              </div>
          </div>
          <div className="media-body custom-block-info pt-0 mt-1">
          {/* {organizations.map(org => ( */}
            <div className="d-flex" >  
            {/* <a href={`/ProfileCardsDetailsList2/${id}`} className="id-fulldetails"> */}
              {/* <Link to={`/ProfileCardsDetailsList/${idnumber}`}> */}
                <small className="small-name">{id}</small>
                {/* </Link> */}
              {/* </a> */}
            </div>
          {/* ))}  */}
            <div className="d-flex text-wrap"  style={{width:"6rem"}}>
              <small className="mt-2 ">{Organization}</small>
            </div>  
            <div className="d-flex">
              <small className="small-name1 mt-2">{experience}</small>
            </div>
            <div className="d-flex">
              <small className="small-name2 mt-2">{location}</small>
            </div>
            {/* <span><FontAwesomeIcon icon={faBuilding} /></span> */}
            <a href={`/ProfileCardsDetailsList/${id}`} className="id-fulldetails">
            <button type="button" className="btn btn-secondary btn-sm">
            <FontAwesomeIcon icon={faArrowRight} />
            </button>
            </a>
          </div>

          
        </div>
      </div>
    </div>
  );
}

export default ProfileCard2;

