import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './EventRegistration.css';
import axios from 'axios'; // Import axios for making HTTP requests

const EventRegistration = () => {
  const { eventid } = useParams(); // Extract eventId from the URL
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  // Handle registration submission
  const handleRegistration = async (e) => {
    e.preventDefault();
    // Create an object containing the registration data
    const registrationData = {
      name,
      email,
      phone,
      eventid, // Convert eventId to a number if needed
    };
    
    
    try {
      // Send a POST request to your server
      const response = await axios.post("http://localhost:8098/event-registration", {
          name,
          email,
          phone,
          eventid
        }
      );
      console.log(response)
      if (response.status ===200) {
        // Registration was successful, you can redirect the user or show a success message
        console.log('Registration successful');
      }
    } catch (error) {
      console.error('Error registering:', error);
    }
  };

  return (
    <div className="event-registartion-container">
     <div>
     <h1>Event Registration</h1>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="phone" className="form-label">
          Phone
        </label>
        <input
          type="tel"
          className="form-control"
          id="phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />
      </div>
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleRegistration}
        >
          Register
        </button>
      </div>
     </div>
    </div>
  );
};

export default EventRegistration;