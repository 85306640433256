// import React, { useState, useEffect } from 'react';
// import axios from 'axios'; // Import axios for making API requests
// import ProfileCard from './ProfileCard';
// import "./ProfileCardList.css";

// function ProfileCardList() {
 
//   const [expertsData, setProfileData] = useState([]);

  
//   const [timestamp, setTimestamp] = useState(new Date());

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setTimestamp(new Date());
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);


//   useEffect(() => {
//     // Fetch Expert profile data from the backend API
//     axios.get('http://localhost:5400/api/expertProfiles') // Update the endpoint URL
//       .then(response => {
//         setProfileData(response.data);
//       })
//       .catch(error => {
//         console.error('Error fetching Expert profile data:', error);
//       });
//   }, []);

//    // Function to group data into rows of 5 cards each
//    const groupDataIntoRows = (data, itemsPerRow) => {
//     const rows = [];
//     for (let i = 0; i < data.length; i += itemsPerRow) {
//       const rowItems = data.slice(i, i + itemsPerRow);
//       rows.push(rowItems);
//     }
//     return rows;
//   };

//   return (
//     <div className="container-fluid pmg-fontfamily px-5">
//       {/* Heading */}                     
//       <div className="mt-3">
//         <div className="custom-text-right">
//           you are now logged in : <span id="timestamp">{timestamp.toLocaleTimeString()}</span>
//         </div>
//         <h3 className="heading">Expert's Profiles</h3>
//       </div>

//       {/* Search bar */}
//       <div className="search-bar-container">
//         <input className="form-control form-control-sm search-input" list="datalistOptions" id="exampleDataList" placeholder="Search Profiles..." />
//         <datalist id="datalistOptions">
//           <option value="python developer" />
//           <option value="java developer"/>
//           <option value="Cardiologist"/>
//           <option value="Dentist"/>
//           <option value="Chicago"/>
//         </datalist>
//       </div>
      
//       <br></br>
//       <br></br>
//       <br></br>




//        {/* Map through each row of data and render the profile cards */}
//        {groupDataIntoRows(expertsData, 5).map((row, rowIndex) => (
//         <div key={rowIndex} className="row">
//           {row.map((data, id) => (
           
//             <ProfileCard
//               key={data.id}
//               imagesrc={data.imagesrc}
//               id={data.id}
//               name={`${data.firstname}`}
//               specialization={data.specialization}
//               experience={data.experienceyear}
//               location={data.city} />
             
//           ))}
//         </div>
//       ))}

//       {/* Navigation buttons */}
//       <div className="d-flex justify-content-center">
//         {/* Previous button */}
//         <a href="/ProfileCardList" >
//         <button className='btn btn-danger btn-sm px-3 me-2'>Previous</button>
//         </a>

//         {/* Next button */}
//         <a href="/ProfileCardList" >
//         <button className='btn btn-danger btn-sm px-3 me-2'>Next</button> 
//         </a>
//       </div>
      
      


//     </div>
//   );
// }

     

// export default ProfileCardList;
// -----------------------
// dummy code tesing




import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for making API requests
import ProfileCard from './ProfileCard';
import "./ProfileCardList.css";

function ProfileCardList() {
 
  const [expertsData, setProfileData] = useState([]);

  
  const [timestamp, setTimestamp] = useState(new Date());

  const [searchQuery, setSearchQuery] = useState('');

  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   // Fetch Expert profile data from the backend API
  //   axios.get('http://localhost:5400/api/expertProfiles') // Update the endpoint URL
  //     .then(response => {
  //       setProfileData(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching Expert profile data:', error);
  //     });
  // }, []);


  useEffect(() => {
    // Fetch Expert profile data from the backend API
    axios.get('http://localhost:5400/api/user-data') // Update the endpoint URL
      .then(response => {
        setProfileData(response.data);
        console.log(response.data)
      })
      .catch(error => {
        console.error('Error fetching Expert profile data:', error);
      });
  }, []);

  const handleSearch = () => {
    const filteredResults = expertsData.filter((profile) =>
      profile.specialization.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

       
      

  return (
    <div className="container-fluid pmg-fontfamily px-5">
      {/* Heading */}                     
      <div className="mt-3">
        <div className="custom-text-right">
          you are now logged in : <span id="timestamp">{timestamp.toLocaleTimeString()}</span>
        </div>
        <h3 className="heading">Expert's Profiles</h3>
      </div>

      {/* Search bar */}
      <div className="search-bar-container">
        <input
          type="text"
          className="form-control form-control-sm search-input"
          placeholder="Search Profiles..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
       
      </div>
      <button onClick={handleSearch}>Search</button>
      <br></br>
      <br></br>
      <br></br>




       {/* Map through each row of data and render the profile cards */}
      
      <div className="row">
        {searchResults.map((data) => (
          <div
            key={data.id}
            className="col-12 col-md-4 col-lg-3"
          >
            <ProfileCard
              key={data.id}
              imagesrc={data.filepath_image}
              id={data.id}
              name={`${data.firstname}`}
              specialization={data.specialization}
              experience={data.experienceyear}
              location={data.city} />
          </div>
        ))}
      </div>
      
      {/* Navigation buttons */}
      {/* <div className="d-flex justify-content-center"> */}
        {/* Previous button */}
        {/* <a href="/ProfileCardList" >
        <button className='btn btn-danger btn-sm px-3 me-2'>Previous</button>
        </a> */}

        {/* Next button */}
        {/* <a href="/ProfileCardList" >
        <button className='btn btn-danger btn-sm px-3 me-2'>Next</button> 
        </a>
      </div> */}
      
      


    </div>
  );
}

     

export default ProfileCardList;