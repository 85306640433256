// import React, { useState } from 'react';
// import axios from 'axios';

// const RegisterAsOrganization = () => {


 
  
//   const [orgName, setOrgName] = useState('');
//   const [orgType, setOrgType] = useState('');

//   const handleOrgNameChange = (event) => {
//     setOrgName(event.target.value);
//   };

//   const handleOrgTypeChange = (event) => {
//     setOrgType(event.target.value);
//   };

//   const [address1, setAddress1] = useState('');
//   const [address2, setAddress2] = useState('');

//   const handleAddress1Change = (event) => {
//     setAddress1(event.target.value);
//   };

//   const handleAddress2Change = (event) => {
//     setAddress2(event.target.value);
//   };

//   const [city, setCity] = useState('');
//   const [state, setState] = useState('');

//   const handleCityChange = (event) => {
//     setCity(event.target.value);
//   };

//   const handleStateChange = (event) => {
//     setState(event.target.value);
//   };

//   const indianStates = [
//     'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 
//     'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 
//     'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 
//     'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 
//     'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'
//   ];

//   const [country, setCountry] = useState('');
//   const [pincode, setPincode] = useState('');

//   const handleCountryChange = (e) => {
//     setCountry(e.target.value);
//   };

//   const handlePincodeChange = (e) => {
//     setPincode(e.target.value);
//   };
  
//   const [orgSize, setOrgSize] = useState('');
//   const [orgFocused, setOrgFocused] = useState('');

//   const handleOrgSizeChange = (event) => {
//     setOrgSize(event.target.value);
//   };

//   const handleOrgFocusedChange = (event) => {
//     setOrgFocused(event.target.value);
//   };

//   const [orgWebsite, setOrgWebsite] = useState('');
//   const [collegeEmail, setCollegeEmail] = useState('');

//   const handleOrgWebsiteChange = (event) => {
//     setOrgWebsite(event.target.value);
//   };

//   const handleCollegeEmailChange = (event) => {
//     setCollegeEmail(event.target.value);
//   };

//   const [selectedInterests, setSelectedInterests] = useState([]);
//   const [selectedHearAboutUs, setSelectedHearAboutUs] = useState([]);


//   const interestOptions = [
//     { value: 'Experts', label: 'Experts'},
//     { value: 'Seminar', label: 'Seminar' },
//     { value: 'Demo', label: 'Demo' },
//     { value: 'Teaching Staff', label: 'Teaching Staff'},
//     { value: 'Consultation',label:'Consultation'},
//     { value: 'Faculty' , label: 'Faculty'},
//     { value: 'Coach/Guide', label:'Coach/Guide'},
//     { value: 'Management', label: 'Management'},
//     { value: 'Development', label: 'Development'},
//     { value: 'Admin', label: 'Admin'},
//     { value: 'Freelancer', label: 'Freelancer'}
// // ... other options
// ];


// const hearAboutUsOptions = [
//     { value: 'News', label: 'News' },
//     { value: 'Social Media', label: 'Social Media' },
//     { value: 'Magazine', label:'Magazine'},
//     { value: 'Advertisement', label: 'Advertisement' },
//     { value: 'Google Search', label: 'Google Search' },
//     { value: 'Friends', label: 'Friends'},
//     { value: 'Others', label: 'Others' }
//     // ...other options
// ];

//   const handleInterestChange = (value) => {
//       if (selectedInterests.includes(value)) {
//           setSelectedInterests(selectedInterests.filter(item => item !== value));
//       } else {
//           setSelectedInterests([...selectedInterests, value]);
//       }
//   };

//   const handleHearAboutUsChange = (value) => {
//       if (selectedHearAboutUs.includes(value)) {
//           setSelectedHearAboutUs(selectedHearAboutUs.filter(item => item !== value));
//       } else {
//           setSelectedHearAboutUs([...selectedHearAboutUs, value]);
//       }
//   };


//   const [pocName, setPocName] = useState('');
//   const [pocDesignation, setPocDesignation] = useState('');

//   const [countryCode, setCountryCode] = useState('');
//   const [contactNumber, setContactNumber] = useState('');
//   const [countryCodeAlternate, setCountryCodeAlternate] = useState('');
//   const [alternateNumber, setAlternateNumber] = useState('');

//   const [email, setEmail] = useState('');
//   const [alternateEmail, setAlternateEmail] = useState('');

//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [confirmPasswordError, setConfirmPasswordError] = useState('');

//   const [profileImageFile, setProfileImageFile] = useState(null);
//   const [uploadStatus, setUploadStatus] = useState(null);
  
//   const handleProfileImageChange = (event) => {
//     setProfileImageFile(event.target.files[0]);
//   };
//   // const [resume, setResume] = useState(null);
//   // const [profileImage, setProfileImage] = useState(null);

//   //     const handleResumeChange = (e) => {
//   //       setResume(e.target.files[0]);
//   //   };

//   //   const handleProfileImageChange = (e) => {
//   //       setProfileImage(e.target.files[0]);
//   //   };





//   const handlePocNameChange = (event) => {
//     setPocName(event.target.value);
//   };

//   const handlePocDesignationChange = (event) => {
//     setPocDesignation(event.target.value);
//   };

//   const handleCountryCodeChange = (event) => {
//     const selectedCountryCode = event.target.value;
//     setCountryCode(selectedCountryCode);
//   };

//   const handleContactNumberChange = (event) => {
//     const enteredContactNumber = event.target.value;
//     setContactNumber(enteredContactNumber);
//   };

//   const handleCountryCodeAlternateChange = (event) => {
//     const selectedCountryCodeAlternate = event.target.value;
//     setCountryCodeAlternate(selectedCountryCodeAlternate);
//   };

//   const handleAlternateNumberChange = (event) => {
//     const enteredAlternateNumber = event.target.value;
//     setAlternateNumber(enteredAlternateNumber);
//   };

//     const handleEmailChange = (e) => {
//         setEmail(e.target.value);
//     };

//     const handleAlternateEmailChange = (e) => {
//         setAlternateEmail(e.target.value);
//     };

//         const handlePasswordChange = (event) => {
//         setPassword(event.target.value);
//         // Reset the confirmPassword error if needed
//         setConfirmPasswordError('');
//     };

//     const handleConfirmPasswordChange = (event) => {
//         setConfirmPassword(event.target.value);
//         // Validate if the confirm password matches the password
//         if (event.target.value !== password) {
//             setConfirmPasswordError('Passwords do not match');
//         } else {
//             setConfirmPasswordError('');
//         }
//     };

    

//       // const handleLogoFileChange = (event) => {
//       //   const selectedFile = event.target.files[0];
//       //   setLogoFile(selectedFile);
//       // };

//       const [agreeChecked, setAgreeChecked] = useState(false);

//       const handleAgreeChange = () => {
//         setAgreeChecked(!agreeChecked);
//       };

//       const handleSubmit =async (e) => {
//         e.preventDefault();
//         if (password !== confirmPassword) {
//             setConfirmPasswordError('Passwords do not match');
//             return;
//         }


//         if (selectedInterests.length === 0 ) {
//             alert('Please make sure to select at least one interest ');
//             return;
//         }

//         if ( selectedHearAboutUs.length === 0) {
//             alert('Please make sure to select at least one option for hearing about us.');
//             return;
//         }
        
//         // send from data submitted by user to "registerasorganization" database 
//         try {
//           const formData={
//             orgName,
//             orgType,
//             address1,
//             address2,
//             city,
//             state,
//             country,
//             pincode,
//             orgSize,
//             orgFocused,
//             orgWebsite,
//             collegeEmail,
//             selectedInterests,
//             selectedHearAboutUs,
//             pocName,
//             pocDesignation,
//             countryCode,
//             contactNumber,
//             countryCodeAlternate,
//             alternateNumber,
//             email,
//             alternateEmail,
//             password,
//             confirmPassword,
//             profileImageFile

//           }
    
//     await axios.post('http://localhost:5400/api/organization/submitForm', formData);
//     console.log('Organization registered successfully');
//     alert('Organization registration was successfull');
//   } catch (error) {
//     console.error('Organization registration was unsuccessfull ', error);
//     alert('Organization registration was unsuccessfull');
//   }

//     // get data submitted by user from "registerasorganization" database and load into cards 
//     const formData2 = new FormData();
//     if (profileImageFile) {
//       formData2.append('profileImage', profileImageFile);
//     }
//     formData2.append('orgName',  orgName);
//     try {
//       const response = await fetch('http://localhost:5400/upload2', {
//         method: 'POST',
//         body: formData2,
//       });

//       const data = await response.json();

//       if (response.status === 200) {
//         setUploadStatus(data.message);
//       } else {
//         setUploadStatus('File upload failed.');
//       }
//     } catch (error) {
//       console.error('Error uploading files:', error);
//       setUploadStatus('An error occurred.');
//     }
 
// };
 

//   return (
//     <React.Fragment>

      
//     <div className="container pmg-fontfamily">
//       <div className="mt-2">
//         <h3 style={{ color: "brown" }}>Organization Registration</h3>
//         <p>Welcome, You are just few steps away from creating your account!</p>

//         <div className="card">
//           <div className="card-body">
            
//             <div className="row">
//               <div className="col-sm-10 col-md-10">
//                 <h4 style={{ color: "brown" }}>
//                   Tell us about your Organization
//                 </h4>
//               </div>
//             </div>

//             <form onSubmit={handleSubmit}>

//               <div>

//               <div className="row">
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <input
//                       type="text"
//                       className="form-control"
//                       name="orgName"
//                       placeholder="Organization Name*"
//                       value={orgName}
//                       onChange={handleOrgNameChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <select
//                       className="form-select"
//                       id="orgTypeSelect"
//                       name="orgType"
//                       value={orgType}
//                       onChange={handleOrgTypeChange}
//                       required
//                     >
//                       <option value="">Organization Type*</option>
//                       <option value="Art's & Science College">Art's & Science College</option>
//                       <option value="University">University</option>
//                       <option value="Software Company">Software Company</option>
//                       <option value="Accounting Form">Accounting Form</option>
//                       <option value="Consulting Form">Consulting Form</option>
//                       <option value="Retail Business">Retail Business</option>
//                       <option value="NGO">NGO</option>
//                       <option value="Skill Development Hub">Skill Development Hub</option>
//                       <option value="Hospital">Hospital</option>
//                       <option value="Public Organization">Public Organization</option>
//                       <option value="Industry">Industry</option>
//                       <option value="Engineering College">Engineering College</option>
//                       <option value="Medical Institutes">Medical Institutes</option>
//                       <option value="Coaching Center">Coaching Center</option>
//                     </select>
//                   </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="address1"
//                       value={address1}
//                       placeholder="Address 1*"
//                       onChange={handleAddress1Change}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="address2"
//                       value={address2}
//                       placeholder="Address 2"
//                       onChange={handleAddress2Change}
//                     />
//                   </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="city"
//                       value={city}
//                       onChange={handleCityChange}
//                       placeholder="City*"
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <select
//                       className="form-select"
//                       id="state"
//                       value={state}
//                       onChange={handleStateChange}
//                       required
//                     >
//                       <option value="">State*</option>
//                       {indianStates.map((stateOption, index) => (
//                         <option key={index} value={stateOption}>
//                           {stateOption}
//                         </option>
//                       ))}
//                     </select>
//                   </div>
//                 </div>
//               </div>
                        
//               <div className="row">
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <select
//                       className="form-select"
//                       id="country"
//                       value={country}
//                       onChange={handleCountryChange}
//                       required
//                     >
//                       <option value="">Country*</option>
//                       <option value="India">India</option>
//                       <option value="USA">USA</option>
//                       <option value="Dubai">Dubai</option>
//                       {/* Other country options */}
//                     </select>
//                   </div>
//                 </div>
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="pincode"
//                       value={pincode}
//                       onChange={handlePincodeChange}
//                       placeholder="Pincode*"
//                       required
//                     />
//                   </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <input
//                       type="text"
//                       className="form-control"
//                       name="Orgsize"
//                       placeholder="Size of Organization*"
//                       value={orgSize}
//                       onChange={handleOrgSizeChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <input
//                       type="text"
//                       className="form-control"
//                       name="Orgfocused"
//                       placeholder="Your Organization Focused Area*"
//                       value={orgFocused}
//                       onChange={handleOrgFocusedChange}
//                       required
//                     />
//                   </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <input
//                       type="text"
//                       className="form-control"
//                       name="orgWebsite"
//                       placeholder="Organization Website*"
//                       value={orgWebsite}
//                       onChange={handleOrgWebsiteChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <input
//                       type="email"
//                       className="form-control"
//                       name="collegeEmail"
//                       placeholder="College Email*"
//                       value={collegeEmail}
//                       onChange={handleCollegeEmailChange}
//                       autoComplete="username"
//                       required
//                     />
//                   </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-sm-6 col-md-6 mt-2">
//                     <label htmlFor="interest">
//                         <h6>Interest In*</h6>
//                     </label><br />
//                     {interestOptions.map(option => (
//                         <div className="form-check form-check-inline" key={option.value}>
//                             <input
//                                 className="form-check-input"
//                                 type="checkbox"
//                                 name="selectedInterest"
//                                 id={`interestRadio_${option.value}`}
//                                 value={option.value}
//                                 onChange={() => handleInterestChange(option.value)}
//                                 checked={selectedInterests.includes(option.value)}
//                             />
//                             <p className="form-check-label" htmlFor={`interestRadio_${option.value}`}>
//                                 {option.label}
//                             </p>
//                         </div>
//                     ))}
//                 </div>

//                 <div className="col-sm-6 col-md-6 mt-2">
//                     <label htmlFor="hearAboutUs">
//                         <h6>How did you hear about us*</h6>
//                     </label><br />
//                     {hearAboutUsOptions.map(option => (
//                         <div className="form-check form-check-inline" key={option.value}>
//                             <input
//                                 className="form-check-input"
//                                 type="checkbox"
//                                 name="selectedHearAboutUs"
//                                 id={`hearAboutUsRadio_${option.value}`}
//                                 value={option.value}
//                                 onChange={() => handleHearAboutUsChange(option.value)}
//                                 checked={selectedHearAboutUs.includes(option.value)}
//                             />
//                             <p className="form-check-label" htmlFor={`hearAboutUsRadio_${option.value}`} >
//                                 {option.label}
//                             </p>
//                         </div>
//                     ))}
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <input
//                       type="text"
//                       className="form-control"
//                       name="pocName"
//                       placeholder="Point of Contact Name*"
//                       value={pocName}
//                       onChange={handlePocNameChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <input
//                       type="text"
//                       className="form-control"
//                       name="pocDesignation"
//                       placeholder="Point of Contact Designation*"
//                       value={pocDesignation}
//                       onChange={handlePocDesignationChange}
//                       required
//                     />
//                   </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-sm-6 col-md-6 mt-2">
//                     <div className="input-group input-group-sm">
//                     <select
//                         className="form-select me-1" // Adjust the classes here
//                         id="countryCode"
//                         value={countryCode}
//                         onChange={handleCountryCodeChange}
//                         style={{ maxWidth: "100px" }} 
//                         required

//                     >
//                         <option value="+91">+91</option>
//                         <option value="+1">+1</option>
//                         {/* Add more country codes as needed */}
//                     </select>
//                     <input
//                         type="text"
//                         className="form-control"
//                         id="contactNumber"
//                         value={contactNumber}
//                         onChange={handleContactNumberChange}
//                         placeholder="Contact Number*"
//                         required
//                     />
//                     </div>
//                 </div>
//                 <div className="col-sm-6 col-md-6 mt-2">
//                     <div className="input-group input-group-sm">
//                     <select
//                         className="form-select me-1" // Adjust the classes here
//                         id="countryCodeAlternate"
//                         value={countryCodeAlternate}
//                         onChange={handleCountryCodeAlternateChange}
//                         style={{ maxWidth: "100px" }} 
                      
//                     >
//                         <option value="+91">+91</option>
//                         <option value="+1">+1</option>
//                         {/* Add more country codes as needed */}
//                     </select>
//                     <input
//                         type="text"
//                         className="form-control"
//                         id="alternateNumber"
//                         value={alternateNumber}
//                         onChange={handleAlternateNumberChange}
//                         placeholder="What's App Number*"
//                     />
//                     </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-sm-6 col-md-6 mt-2">
//                     <div className="input-group input-group-sm">
//                         <input
//                             type="email"
//                             className="form-control"
//                             id="email"
//                             value={email}
//                             onChange={handleEmailChange}
//                             placeholder="E-mail*"
//                             autoComplete="username"
//                             required
//                         />
//                     </div>
//                 </div>
//                 <div className="col-sm-6 col-md-6 mt-2">
//                     <div className="input-group input-group-sm">
//                         <input
//                             type="email"
//                             className="form-control"
//                             id="alternateEmail"
//                             value={alternateEmail}
//                             placeholder="Alternative E-mail"
//                             onChange={handleAlternateEmailChange}
//                             autoComplete="username"
//                         />
//                     </div>
//                 </div>
//               </div>

//               <div className="row">
//                 <div className="col-sm-6 col-md-6 mt-2">
//                     <div className="input-group input-group-sm">
//                         <input
//                             type="password"
//                             className="form-control"
//                             placeholder="Pin*"
//                             value={password}
//                             onChange={handlePasswordChange}
//                             autocomplete="new-password"
//                             required
//                         />
//                     </div>
//                     <p className="pmg-note-points-color pmg-small-text emphasized">*Set 6 digit Pin</p>
//                 </div>
//                 <div className="col-sm-6 col-md-6 mt-2">
//                     <div className="input-group input-group-sm">
//                         <input
//                             type="password"
//                             className="form-control"
//                             placeholder="Confirm Pin*"
//                             value={confirmPassword}
//                             onChange={handleConfirmPasswordChange}
//                             autoComplete="new-password"
//                             required
//                         />
//                     </div>
//                     <p className="pmg-note-points-color pmg-small-text emphasized">*Set 6 digit Confirm Pin</p>
//                     {confirmPasswordError && (
//                         <div className="text-danger">{confirmPasswordError}</div>
//                     )}
//                 </div>
//               </div>

//               {/* <div className="row">
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <div className="input-group input-group-sm">
//                     <input
//                       type="file"
//                       className="form-control"
//                       name="OrganizationLogo"
//                       value={logoFile}
//                       accept=".jpg, .jpeg, .png"
//                       onChange={handleLogoFileChange}
                     
//                     />
//                   </div>
//                   <p className="pmg-note-points-color pmg-small-text emphasized">Upload Organization logo with size less than 200KB</p>
//                 </div>
//               </div> */}

//               <div className='row'>
//                 <div className="col-sm-6 col-md-6 mt-2">
//                   <h6>Profile Image</h6>
//                   <div className="input-group input-group-sm">
//                       <input
//                           type="file"
//                           className="form-control"
//                           id="profileImage"
//                           name="profileImage"
//                           accept=".jpg, .jpeg, .png"
//                           onChange={handleProfileImageChange}
//                       />
//                   </div>
//                   <p className="pmg-note-points-color pmg-small-text emphasized">
//                       Photo Format: jpg, jpeg and Size cannot be more than 100KB
//                   </p>
//                 </div>
//               </div>

//               <div className="row ">
//                 <div className="col-sm-12 col-md-12">
//                     <div className="form-check">
//                         <input
//                             type="checkbox"
//                             className="form-check-input"
//                             id="check1"
//                             name="option1"
//                             value="something"
//                             checked={agreeChecked}
//                             onChange={handleAgreeChange}
//                         />

//                         <p className="form-check-label" htmlFor="check1">
//                             I agree this platform utilizes my personal information for any communication. I accept  
//                             {" "} 
//                             <a href="#" className="pmg-terms-link">
//                             terms and conditions
//                             </a>{" "}
//                             of the platform.
//                         </p>
//                     </div>
//                 </div>
//               </div>

//               <div className="row">
//                   <div className="text-center">
//                       {/* Disable the "Register" button based on the checkbox state */}
//                       <button
//                           type="submit"
//                           className="btn btn-danger btn-sm px-3 me-2"
//                           disabled={!agreeChecked}
//                           value="submit"
//                       >
//                           Register
//                       </button>
//                   </div>
//               </div>

//               </div>

//             </form>

//           </div>
//         </div>

//       </div>
//     </div>
      
     
      
      
   

    
//     </React.Fragment>
//   );
// };



// export default RegisterAsOrganization











import React, { useState } from 'react';
import axios from 'axios';

const RegisterAsOrganization = () => {


 
  
  const [orgName, setOrgName] = useState('');
  const [orgType, setOrgType] = useState('');

  const handleOrgNameChange = (event) => {
    setOrgName(event.target.value);
  };

  const handleOrgTypeChange = (event) => {
    setOrgType(event.target.value);
  };

  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');

  const handleAddress1Change = (event) => {
    setAddress1(event.target.value);
  };

  const handleAddress2Change = (event) => {
    setAddress2(event.target.value);
  };

  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const indianStates = [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 
    'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 
    'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 
    'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 
    'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'
  ];

  const [country, setCountry] = useState('');
  const [pincode, setPincode] = useState('');

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
  };
  
  const [orgSize, setOrgSize] = useState('');
  const [orgFocused, setOrgFocused] = useState('');

  const handleOrgSizeChange = (event) => {
    setOrgSize(event.target.value);
  };

  const handleOrgFocusedChange = (event) => {
    setOrgFocused(event.target.value);
  };

  const [orgWebsite, setOrgWebsite] = useState('');
  const [collegeEmail, setCollegeEmail] = useState('');

  const handleOrgWebsiteChange = (event) => {
    setOrgWebsite(event.target.value);
  };

  const handleCollegeEmailChange = (event) => {
    setCollegeEmail(event.target.value);
  };

  const [selectedInterests, setSelectedInterests] = useState([]);
  const [selectedHearAboutUs, setSelectedHearAboutUs] = useState([]);


  const interestOptions = [
    { value: 'Experts', label: 'Experts'},
    { value: 'Seminar', label: 'Seminar' },
    { value: 'Demo', label: 'Demo' },
    { value: 'Teaching Staff', label: 'Teaching Staff'},
    { value: 'Consultation',label:'Consultation'},
    { value: 'Faculty' , label: 'Faculty'},
    { value: 'Coach/Guide', label:'Coach/Guide'},
    { value: 'Management', label: 'Management'},
    { value: 'Development', label: 'Development'},
    { value: 'Admin', label: 'Admin'},
    { value: 'Freelancer', label: 'Freelancer'}
// ... other options
];


const hearAboutUsOptions = [
    { value: 'News', label: 'News' },
    { value: 'Social Media', label: 'Social Media' },
    { value: 'Magazine', label:'Magazine'},
    { value: 'Advertisement', label: 'Advertisement' },
    { value: 'Google Search', label: 'Google Search' },
    { value: 'Friends', label: 'Friends'},
    { value: 'Others', label: 'Others' }
    // ...other options
];

  const handleInterestChange = (value) => {
      if (selectedInterests.includes(value)) {
          setSelectedInterests(selectedInterests.filter(item => item !== value));
      } else {
          setSelectedInterests([...selectedInterests, value]);
      }
  };

  const handleHearAboutUsChange = (value) => {
      if (selectedHearAboutUs.includes(value)) {
          setSelectedHearAboutUs(selectedHearAboutUs.filter(item => item !== value));
      } else {
          setSelectedHearAboutUs([...selectedHearAboutUs, value]);
      }
  };


  const [pocName, setPocName] = useState('');
  const [pocDesignation, setPocDesignation] = useState('');

  const [countryCode, setCountryCode] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [countryCodeAlternate, setCountryCodeAlternate] = useState('');
  const [alternateNumber, setAlternateNumber] = useState('');

  const [email, setEmail] = useState('');
  const [alternateEmail, setAlternateEmail] = useState('');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');


  const handlePocNameChange = (event) => {
    setPocName(event.target.value);
  };

  const handlePocDesignationChange = (event) => {
    setPocDesignation(event.target.value);
  };

  const handleCountryCodeChange = (event) => {
    const selectedCountryCode = event.target.value;
    setCountryCode(selectedCountryCode);
  };

  const handleContactNumberChange = (event) => {
    const enteredContactNumber = event.target.value;
    setContactNumber(enteredContactNumber);
  };

  const handleCountryCodeAlternateChange = (event) => {
    const selectedCountryCodeAlternate = event.target.value;
    setCountryCodeAlternate(selectedCountryCodeAlternate);
  };

  const handleAlternateNumberChange = (event) => {
    const enteredAlternateNumber = event.target.value;
    setAlternateNumber(enteredAlternateNumber);
  };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleAlternateEmailChange = (e) => {
        setAlternateEmail(e.target.value);
    };

        const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        // Reset the confirmPassword error if needed
        setConfirmPasswordError('');
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        // Validate if the confirm password matches the password
        if (event.target.value !== password) {
            setConfirmPasswordError('Passwords do not match');
        } else {
            setConfirmPasswordError('');
        }
    };

    

    const [profileImageFile, setProfileImageFile] = useState(null);
    // const [uploadStatus, setUploadStatus] = useState(null);
    const handleProfileImageChange = (event) => {
        const file = (event.target.files[0]);
        const reader = new FileReader();

        reader.onload=()=>{
            setProfileImageFile(reader.result)
        }
        reader.readAsDataURL(file);
    };
console.log(profileImageFile)
      const [agreeChecked, setAgreeChecked] = useState(false);

      const handleAgreeChange = () => {
        setAgreeChecked(!agreeChecked);
      };

      const handleSubmit =async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
          setConfirmPasswordError('Passwords do not match');
          return;
        }
        else {
          setConfirmPasswordError('');
        }

        if (selectedInterests.length === 0 ) {
            alert('Please make sure to select at least one interest ');
            return;
        }

        if ( selectedHearAboutUs.length === 0) {
            alert('Please make sure to select at least one option for hearing about us.');
            return;
        }
        
        // send from data submitted by user to "registerasorganization" database 
        try {
          const formData={
            orgName,
            orgType,
            address1,
            address2,
            city,
            state,
            country,
            pincode,
            orgSize,
            orgFocused,
            orgWebsite,
            collegeEmail,
            selectedInterests,
            selectedHearAboutUs,
            pocName,
            pocDesignation,
            countryCode,
            contactNumber,
            countryCodeAlternate,
            alternateNumber,
            email,
            alternateEmail,
            password,
            confirmPassword,
            profileImageFile

          }
    
    await axios.post('http://localhost:5400/api/organization/submitForm', formData);
    console.log('Organization registered successfully');
    console.log(profileImageFile)
    alert('Organization registration was successfull');
  } catch (error) {
    console.error('Organization registration was unsuccessfull ', error);
    alert('Organization registration was unsuccessfull');
  }

  
  };

  return (
    <React.Fragment>

      
    <div className="container pmg-fontfamily">
      <div className="mt-2">
        <h3 style={{ color: "brown" }}>Organization Registration</h3>
        <p>Welcome, You are just few steps away from creating your account!</p>

        <div className="card">
          <div className="card-body">
            
            <div className="row">
              <div className="col-sm-10 col-md-10">
                <h4 style={{ color: "brown" }}>
                  Tell us about your Organization
                </h4>
              </div>
            </div>

            <form onSubmit={handleSubmit}>

              <div>

              <div className="row">
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      name="orgName"
                      placeholder="Organization Name*"
                      value={orgName}
                      onChange={handleOrgNameChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <select
                      className="form-select"
                      id="orgTypeSelect"
                      name="orgType"
                      value={orgType}
                      onChange={handleOrgTypeChange}
                      required
                    >
                      <option value="">Organization Type*</option>
                      <option value="Art's & Science College">Art's & Science College</option>
                      <option value="University">University</option>
                      <option value="Software Company">Software Company</option>
                      <option value="Accounting Form">Accounting Form</option>
                      <option value="Consulting Form">Consulting Form</option>
                      <option value="Retail Business">Retail Business</option>
                      <option value="NGO">NGO</option>
                      <option value="Skill Development Hub">Skill Development Hub</option>
                      <option value="Hospital">Hospital</option>
                      <option value="Public Organization">Public Organization</option>
                      <option value="Industry">Industry</option>
                      <option value="Engineering College">Engineering College</option>
                      <option value="Medical Institutes">Medical Institutes</option>
                      <option value="Coaching Center">Coaching Center</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      id="address1"
                      value={address1}
                      placeholder="Address 1*"
                      onChange={handleAddress1Change}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      id="address2"
                      value={address2}
                      placeholder="Address 2"
                      onChange={handleAddress2Change}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      value={city}
                      onChange={handleCityChange}
                      placeholder="City*"
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <select
                      className="form-select"
                      id="state"
                      value={state}
                      onChange={handleStateChange}
                      required
                    >
                      <option value="">State*</option>
                      {indianStates.map((stateOption, index) => (
                        <option key={index} value={stateOption}>
                          {stateOption}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
                        
              <div className="row">
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <select
                      className="form-select"
                      id="country"
                      value={country}
                      onChange={handleCountryChange}
                      required
                    >
                      <option value="">Country*</option>
                      <option value="India">India</option>
                      <option value="USA">USA</option>
                      <option value="Dubai">Dubai</option>
                      {/* Other country options */}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      id="pincode"
                      value={pincode}
                      onChange={handlePincodeChange}
                      placeholder="Pincode*"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      name="Orgsize"
                      placeholder="Size of Organization*"
                      value={orgSize}
                      onChange={handleOrgSizeChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      name="Orgfocused"
                      placeholder="Your Organization Focused Area*"
                      value={orgFocused}
                      onChange={handleOrgFocusedChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      name="orgWebsite"
                      placeholder="Organization Website*"
                      value={orgWebsite}
                      onChange={handleOrgWebsiteChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <input
                      type="email"
                      className="form-control"
                      name="collegeEmail"
                      placeholder="College Email*"
                      value={collegeEmail}
                      onChange={handleCollegeEmailChange}
                      autoComplete="username"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 mt-2">
                    <label htmlFor="interest">
                        <h6>Interest In*</h6>
                    </label><br />
                    {interestOptions.map(option => (
                        <div className="form-check form-check-inline" key={option.value}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="selectedInterest"
                                id={`interestRadio_${option.value}`}
                                value={option.value}
                                onChange={() => handleInterestChange(option.value)}
                                checked={selectedInterests.includes(option.value)}
                            />
                            <p className="form-check-label" htmlFor={`interestRadio_${option.value}`}>
                                {option.label}
                            </p>
                        </div>
                    ))}
                </div>

                <div className="col-sm-6 col-md-6 mt-2">
                    <label htmlFor="hearAboutUs">
                        <h6>How did you hear about us*</h6>
                    </label><br />
                    {hearAboutUsOptions.map(option => (
                        <div className="form-check form-check-inline" key={option.value}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="selectedHearAboutUs"
                                id={`hearAboutUsRadio_${option.value}`}
                                value={option.value}
                                onChange={() => handleHearAboutUsChange(option.value)}
                                checked={selectedHearAboutUs.includes(option.value)}
                            />
                            <p className="form-check-label" htmlFor={`hearAboutUsRadio_${option.value}`} >
                                {option.label}
                            </p>
                        </div>
                    ))}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      name="pocName"
                      placeholder="Point of Contact Name*"
                      value={pocName}
                      onChange={handlePocNameChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <input
                      type="text"
                      className="form-control"
                      name="pocDesignation"
                      placeholder="Point of Contact Designation*"
                      value={pocDesignation}
                      onChange={handlePocDesignationChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 mt-2">
                    <div className="input-group input-group-sm">
                    <select
                        className="form-select me-1" // Adjust the classes here
                        id="countryCode"
                        value={countryCode}
                        onChange={handleCountryCodeChange}
                        style={{ maxWidth: "100px" }} 
                        required
                    >
                        <option value="+">+</option>
                        <option value="+91">+91</option>
                        <option value="+1">+1</option>
                        {/* Add more country codes as needed */}
                    </select>
                    <input
                        type="text"
                        className="form-control"
                        id="contactNumber"
                        value={contactNumber}
                        onChange={handleContactNumberChange}
                        placeholder="Contact Number*"
                        required
                    />
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 mt-2">
                    <div className="input-group input-group-sm">
                    <select
                        className="form-select me-1" // Adjust the classes here
                        id="countryCodeAlternate"
                        value={countryCodeAlternate}
                        onChange={handleCountryCodeAlternateChange}
                        style={{ maxWidth: "100px" }} 
                      
                    >
                        <option value="+">+</option>
                        <option value="+91">+91</option>
                        <option value="+1">+1</option>
                        {/* Add more country codes as needed */}
                    </select>
                    <input
                        type="text"
                        className="form-control"
                        id="alternateNumber"
                        value={alternateNumber}
                        onChange={handleAlternateNumberChange}
                        placeholder="What's App Number*"
                    />
                    </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 mt-2">
                    <div className="input-group input-group-sm">
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="E-mail*"
                            autoComplete="username"
                            required
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 mt-2">
                    <div className="input-group input-group-sm">
                        <input
                            type="email"
                            className="form-control"
                            id="alternateEmail"
                            value={alternateEmail}
                            placeholder="Alternative E-mail"
                            onChange={handleAlternateEmailChange}
                            autoComplete="username"
                        />
                    </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6 mt-2">
                    <div className="input-group input-group-sm">
                        <input
                            type="password"
                            className="form-control"
                            placeholder="Pin*"
                            value={password}
                            onChange={handlePasswordChange}
                            autocomplete="new-password"
                            required
                        />
                    </div>
                    <p className="pmg-note-points-color pmg-small-text emphasized">*Set 6 digit Pin</p>
                </div>
                <div className="col-sm-6 col-md-6 mt-2">
                    <div className="input-group input-group-sm">
                        <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm Pin*"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            autoComplete="new-password"
                            required
                        />
                    </div>
                    <p className="pmg-note-points-color pmg-small-text emphasized">*Set 6 digit Confirm Pin</p>
                    {confirmPasswordError && (
                        <div className="text-danger">{confirmPasswordError}</div>
                    )}
                </div>
              </div>

              {/* <div className="row">
                <div className="col-sm-6 col-md-6 mt-2">
                  <div className="input-group input-group-sm">
                    <input
                      type="file"
                      className="form-control"
                      name="OrganizationLogo"
                      value={logoFile}
                      accept=".jpg, .jpeg, .png"
                      onChange={handleLogoFileChange}
                     
                    />
                  </div>
                  <p className="pmg-note-points-color pmg-small-text emphasized">Upload Organization logo with size less than 200KB</p>
                </div>
              </div> */}
              <div className='row'>
                <div className="col-sm-6 col-md-6 mt-2">
                    <h6>Organization Logo</h6>
                    <div className="input-group input-group-sm">
                        <input
                            type="file"
                            className="form-control"
                            id="profileImage"
                            name="profileImage"
                            accept=".jpg, .jpeg, .png"
                            onChange={handleProfileImageChange}
                        />
                    </div>
                    <p className="pmg-note-points-color pmg-small-text emphasized">
                        Photo Format: jpg, jpeg and Size cannot be more than 100KB
                    </p>
                </div>
              </div>

              <div className="row ">
                <div className="col-sm-12 col-md-12">
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="check1"
                            name="option1"
                            value="something"
                            checked={agreeChecked}
                            onChange={handleAgreeChange}
                        />

                        <p className="form-check-label" htmlFor="check1">
                            I agree this platform utilizes my personal information for any communication. I accept  
                            {" "} 
                            <a href="#" className="pmg-terms-link">
                            terms and conditions
                            </a>{" "}
                            of the platform.
                        </p>
                    </div>
                </div>
              </div>

              <div className="row">
                  <div className="text-center">
                      {/* Disable the "Register" button based on the checkbox state */}
                      <button
                          type="submit"
                          className="btn btn-danger btn-sm px-3 me-2"
                          disabled={!agreeChecked}
                          value="submit"
                      >
                          Register
                      </button>
                  </div>
              </div>

              </div>

            </form>

          </div>
        </div>

      </div>
    </div>
      
     
      
      
   

    
    </React.Fragment>
  );
};



export default RegisterAsOrganization










